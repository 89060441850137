import { ApolloError, useMutation, useQuery } from '@apollo/client';
import { Button, Icon } from '@elipssolution/harfang';
import { mdiPencil, mdiPlus } from '@mdi/js';
import { Divider, styled } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

import TemplateCustomerFileInfosForm from './TemplateCustomerFileInfosForm';
import TemplateCustomerFileLineSection from './TemplateCustomerFileLineSection';
import ConfirmationButton from '../../../../../../../components/ConfirmationButton';
import SettingsDialogPage from '../../../../../../../components/SettingsDialogPage';
import { useSettingsDialog } from '../../../../../../../hooks/useSettingsDialog';
import { CustomerFileType } from '../../../../../../../types/customerFile';
import { generateErrorInformations } from '../../../../../../../utils/errorHandler';
import {
	CREATE_SETTING_CUSTOMER_FILE_TEMPLATE,
	CreateSettingCustomerFileTemplateType,
	FETCH_SETTING_CUSTOMER_FILE_TEMPLATE,
	FetchSettingCustomerFileTemplateType,
	REMOVE_SETTING_CUSTOMER_FILE_TEMPLATE,
	RemoveSettingCustomerFileTemplateType,
	UPDATE_SETTING_CUSTOMER_FILE_TEMPLATE,
	UpdateSettingCustomerFileTemplateType,
} from '../../../../../api/settingTemplate';
import { ErrorType } from '../../../../../types/error';
import {
	DirectionEnum,
	SettingCustomerFileTemplateFormType,
	SettingCustomerFileTemplateLineMutationType,
	SettingCustomerFileTemplateLineType,
	SettingCustomerFileTemplateMutationType,
	SettingCustomerFileTemplateWithErrorFieldsType,
	SettingCustomerFileTemplateType,
	TemplateLineErrorMessageType,
} from '../../../../../types/settingTemplate';

const ActionWrapper = styled('div')({
	display: 'flex',
	flexDirection: 'row-reverse',
	justifyContent: 'space-between',
});

const ErrorWrapper = styled('div')(({ theme: { palette, shape } }) => ({
	height: 36,
	width: '50%',
	display: 'grid',
	placeItems: 'center',
	color: palette.error.main,
	backgroundColor: `${palette.error.main}1A`,
	borderRadius: shape.borderRadius * 2,
}));

type TemplateCustomerFileFormProps = {
	connector?: CustomerFileType['connector'];
	customerFileId?: CustomerFileType['id'];
	selectedTemplate?: SettingCustomerFileTemplateType;
};
const getError = ({ fieldValue }: ErrorType) => ({ message: `"${fieldValue}" est invalide` });

const TemplateCustomerFileForm = ({ connector, customerFileId, selectedTemplate }: TemplateCustomerFileFormProps) => {
	const { back } = useSettingsDialog();

	const {
		control,
		formState: { dirtyFields, isDirty, isValid, errors: formErrors },
		handleSubmit,
		setError: setFormError,
		reset,
		setValue,
		watch,
		clearErrors,
	} = useForm<SettingCustomerFileTemplateFormType>({
		mode: 'onBlur',
	});

	const formAnalyticalDimension = watch('analyticalDimension');
	const formAnalyticalSectionCaption = watch('analyticalSectionCaption');
	const formTemplateName = watch('name');
	const formTemplateLines = watch('templateLines');
	const formDefaultEntryName = watch('defaultEntryName');

	const [template, setTemplate] = useState(selectedTemplate);
	const [errorMessage, setErrorMessage] = useState<string>();
	const [templateLinesErrorMessage, setTemplateLinesErrorMessage] = useState<TemplateLineErrorMessageType[]>([]);

	const { isTemplateInherited, isTemplateOverload, templateId, templateName } = useMemo(() => {
		const { id, isInherited, isOverload, name } = template ?? {};

		return {
			templateId: id,
			isTemplateInherited: isInherited,
			isTemplateOverload: isOverload,
			templateName: name,
		};
	}, [template]);

	const applyTemplateToForm = useCallback(
		(templateToApply: SettingCustomerFileTemplateWithErrorFieldsType) => {
			const {
				analyticalSectionCaption,
				analyticalDimension,
				defaultEntryName,
				isInherited,
				isOverload,
				journal,
				name,
				paymentMode,
				templateLines,
				state,
				validUntil,
			} = templateToApply;

			const defaultValues: { [key: string]: unknown } = {
				...(validUntil && {
					validUntil: new Date(validUntil),
				}),
				analyticalDimension: analyticalDimension?.data ?? undefined,
				paymentMode: paymentMode?.data ?? undefined,
				templateLines: templateLines.map(({ account, subaccount, analyticalSection, ...rest }) => ({
					...rest,
					analyticalSection: analyticalSection?.data ?? undefined,
					account: account?.data ?? undefined,
					subaccount: subaccount?.data ?? undefined,
				})),
				analyticalSectionCaption,
				defaultEntryName,
				isInherited,
				isOverload,
				journal: journal?.data ?? undefined,
				name,
				state,
			};

			reset(defaultValues);

			if (journal?.error) {
				setFormError('journal', getError(journal.error));
			}
			if (paymentMode?.error) {
				setFormError('paymentMode', getError(paymentMode.error));
			}

			if (analyticalDimension?.error) {
				setFormError('analyticalDimension', getError(analyticalDimension.error));
			}

			const errors = templateLines.map(({ account, subaccount, analyticalSection }) => ({
				account: account?.error ? getError(account.error).message : undefined,
				subaccount: subaccount?.error ? getError(subaccount.error).message : undefined,
				analyticalSection: analyticalSection?.error ? getError(analyticalSection.error).message : undefined,
			}));

			setTemplateLinesErrorMessage(errors);
		},
		[reset, setFormError],
	);

	const { data: customerFileTemplateData } = useQuery<FetchSettingCustomerFileTemplateType>(
		FETCH_SETTING_CUSTOMER_FILE_TEMPLATE,
		{
			errorPolicy: 'all',
			skip: !selectedTemplate,
			variables: { quickentrySettingCustomerFileTemplateId: selectedTemplate?.id },
			onError: (error) => {
				if (error?.message?.includes(`Cannot destructure property 'id' of 'analyticalDimension' as it is null.`)) {
					setFormError('analyticalDimension', { message: 'Le code de dimension analytique est invalide' });
				}
			},
		},
	);

	const isTemplateLinesValid = useCallback(
		() =>
			formTemplateLines?.length >= 2 &&
			formTemplateLines?.some(({ direction }) => direction === DirectionEnum.CREDIT) &&
			formTemplateLines?.some(({ direction }) => direction === DirectionEnum.DEBIT),
		[formTemplateLines],
	);

	const isFormValid = useMemo(() => isTemplateLinesValid() && isValid, [isTemplateLinesValid, isValid]);

	const [createSettingCustomerFileTemplate, { loading: isCreateLoading }] =
		useMutation<CreateSettingCustomerFileTemplateType>(CREATE_SETTING_CUSTOMER_FILE_TEMPLATE);

	const [updateSettingCustomerFileTemplate, { loading: isUpdateLoading }] =
		useMutation<UpdateSettingCustomerFileTemplateType>(UPDATE_SETTING_CUSTOMER_FILE_TEMPLATE);

	const [removeSettingCustomerFileTemplate, { loading: isRemoveLoading }] =
		useMutation<RemoveSettingCustomerFileTemplateType>(REMOVE_SETTING_CUSTOMER_FILE_TEMPLATE, {
			onCompleted: back,
		});

	const isLoading = useMemo(() => isCreateLoading || isUpdateLoading, [isCreateLoading, isUpdateLoading]);

	const isMutationButtonDisabled = useMemo(
		() => isRemoveLoading || isCreateLoading || isUpdateLoading || !isValid || !isDirty || !isFormValid,
		[isCreateLoading, isDirty, isFormValid, isRemoveLoading, isUpdateLoading, isValid],
	);

	const mutationButtonName = useMemo(() => {
		if (isTemplateInherited && templateId) return 'Surcharger le modèle';

		return templateId ? 'Modifier' : 'Ajouter';
	}, [isTemplateInherited, templateId]);

	const mutationButtonStartIcon = useMemo(() => <Icon path={templateId ? mdiPencil : mdiPlus} />, [templateId]);

	const handleTemplateLinesChange = useCallback(
		(newLines: SettingCustomerFileTemplateLineType[]) =>
			setValue('templateLines', newLines, {
				shouldDirty: true,
				shouldValidate: true,
			}),
		[setValue],
	);

	const onSubmit = useCallback(
		(values: SettingCustomerFileTemplateFormType) => {
			setErrorMessage('');

			const {
				name,
				defaultEntryName: defaultEntryNameValues,
				validUntil,
				analyticalSectionCaption: analyticalSectionCaptionValues,
				state,
				analyticalDimension: analyticalDimensionValues,
				journal,
				paymentMode,
				templateLines: lines,
			} = values;

			const validUntilString = validUntil?.toISOString();

			const tmpTemplate: Partial<SettingCustomerFileTemplateMutationType> = {
				defaultEntryName: defaultEntryNameValues,
				validUntil: validUntilString,
				analyticalSectionCaption: analyticalSectionCaptionValues,
				analyticalDimensionId: analyticalDimensionValues?.id,
				journalId: journal?.id,
				paymentModeId: paymentMode?.id,
				name,
				state,
			};

			if (lines) {
				tmpTemplate.templateLines = lines.map(
					(
						{
							account,
							analyticalSection,
							defaultTransactionName,
							direction,
							isAnalyticalEnabled,
							name: templateLineName,
							subaccount,
							subaccountPrefix,
							type,
						},
						index: number,
					) => ({
						defaultTransactionName,
						direction,
						isAnalyticalEnabled,
						subaccountPrefix,
						type,
						accountId: account?.id,
						analyticalSectionId: analyticalSection?.id,
						name: templateLineName,
						rank: index,
						subaccountId: subaccount?.id,
					}),
				) as SettingCustomerFileTemplateLineMutationType[];
			}

			const modeMutationInfoMap = new Map([
				[
					'create',
					{
						mutation: createSettingCustomerFileTemplate,
						mutationName: 'createSettingCustomerFileTemplate',
						inputValues: {
							customerFileId,
							...(templateId && { overriddenTemplateId: templateId }),
							...tmpTemplate,
						},
					},
				],
				[
					'update',
					{
						mutation: updateSettingCustomerFileTemplate,
						mutationName: 'updateSettingCustomerFileTemplate',
						inputValues: {
							id: templateId,
							...tmpTemplate,
						},
					},
				],
			]);

			const {
				mutation,
				mutationName = '',
				inputValues,
			} = modeMutationInfoMap.get(!templateId || (isTemplateInherited && templateId) ? 'create' : 'update') ?? {};

			return mutation?.({
				variables: {
					[`${mutationName}Input`]: inputValues,
				},
			})
				.then(({ data }) => {
					if (!data) return;

					const { id }: { id: string } = data[`quickentry_${mutationName}` as keyof typeof data];

					setTemplate({
						...values,
						isInherited: false,
						isOverload: isTemplateInherited ?? false,
						validUntil: validUntilString,
						id,
						state,
					});
				})
				.catch((error: ApolloError) =>
					setErrorMessage(
						generateErrorInformations({
							error,
							resource: templateId
								? 'quickentry_updateSettingCustomerFileTemplate'
								: 'quickentry_createSettingCustomerFileTemplate',
						})?.message,
					),
				);
		},
		[
			createSettingCustomerFileTemplate,
			updateSettingCustomerFileTemplate,
			customerFileId,
			isTemplateInherited,
			templateId,
		],
	);

	const handleRemoval = useCallback(async () => {
		await removeSettingCustomerFileTemplate({
			variables: { quickentryRemoveSettingCustomerFileTemplateId: templateId },
		});
	}, [removeSettingCustomerFileTemplate, templateId]);

	// Resetting form error if there's a change in the form
	useEffect(() => {
		isDirty && setErrorMessage('');
	}, [isDirty]);

	// Syncs the templateName field with the defaultEntryName field
	useEffect(() => {
		!dirtyFields.defaultEntryName && setValue('defaultEntryName', formTemplateName);
	}, [setValue, dirtyFields, formTemplateName]);

	useEffect(() => {
		if (customerFileTemplateData) {
			applyTemplateToForm(customerFileTemplateData?.quickentry_settingCustomerFileTemplate);
		}
	}, [applyTemplateToForm, customerFileTemplateData, customerFileTemplateData?.quickentry_settingCustomerFileTemplate]);

	return (
		<SettingsDialogPage title={templateName ?? "Ajout d'un modèle de pièce"}>
			<TemplateCustomerFileInfosForm
				control={control}
				customerFileId={customerFileId}
				isDomainRelated={isTemplateInherited || isTemplateOverload}
				clearErrors={clearErrors}
				setValue={setValue}
				formErrors={formErrors}
			/>

			<Divider />

			<TemplateCustomerFileLineSection
				analyticalDimensionId={formAnalyticalDimension?.id}
				analyticalSectionCaption={formAnalyticalSectionCaption}
				connector={connector}
				customerFileId={customerFileId}
				defaultEntryName={formDefaultEntryName}
				onChange={handleTemplateLinesChange}
				templateLines={formTemplateLines ?? []}
				templateLinesErrorMessage={templateLinesErrorMessage}
				onTemplateLinesErrorMessagesChange={setTemplateLinesErrorMessage}
			/>

			<ActionWrapper>
				<Button
					disabled={isMutationButtonDisabled}
					onClick={handleSubmit(onSubmit)}
					startIcon={mutationButtonStartIcon}
					variant="contained"
				>
					{mutationButtonName}
				</Button>

				{errorMessage && <ErrorWrapper>{errorMessage}</ErrorWrapper>}

				{!isTemplateInherited && templateId && (
					<ConfirmationButton
						color="error"
						confirmLabel={!isTemplateInherited && isTemplateOverload ? 'Revenir au modèle domaine' : 'Supprimer'}
						disabled={isLoading}
						onConfirm={handleRemoval}
						title={`Êtes-vous sûr de vouloir supprimer le modèle ${templateName ?? ''} ?`}
						variant="outlined"
					/>
				)}
			</ActionWrapper>
		</SettingsDialogPage>
	);
};

export default TemplateCustomerFileForm;
