import { useMutation, useQuery } from '@apollo/client';
import {
	Button,
	Icon,
	SettingsGroup,
	SettingsItemCheckbox,
	SettingsItemTextField,
	Tooltip,
} from '@elipssolution/harfang';
import { mdiAlertCircle, mdiPencil, mdiPlus } from '@mdi/js';
import { Stack, Typography, styled } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import InternalGroupPermissionModules from './InternalGroupPermissionModules';
import UsersByGroupTable from './UsersByGroupTable';
import ConfirmationButton from '../../../../components/ConfirmationButton';
import SettingsDialogPage from '../../../../components/SettingsDialogPage';
import { useSettingsDialog } from '../../../../hooks/useSettingsDialog';
import { generateErrorInformations } from '../../../../utils/errorHandler';
import {
	CREATE_INTERNAL_GROUP,
	CreateInternalGroupType,
	FETCH_INTERNAL_GROUP,
	FetchInternalGroupType,
	REMOVE_INTERNAL_GROUP,
	UPDATE_INTERNAL_GROUP,
	UpdateInternalGroupType,
} from '../../../api/internalGroup';
import { useSession } from '../../../components/SessionProvider';
import { GroupType } from '../../../types/group';

const ErrorWrapper = styled('div')(({ theme: { spacing, palette, shape } }) => ({
	overflow: 'auto',
	minHeight: 100,
	minWidth: '50%',
	maxWidth: '75%',
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
	padding: spacing(1),
	gap: spacing(1),
	margin: '16px auto 0 auto',
	color: palette.error.main,
	backgroundColor: `${palette.error.main}1A`,
	borderRadius: shape.borderRadius * 2,
}));

type FormType = {
	name?: string;
	isDefault?: boolean;
};

type InternalGroupFormProps = {
	group?: GroupType;
};

const InternalGroupForm = ({ group: initialGroup }: InternalGroupFormProps) => {
	const { back } = useSettingsDialog();
	const { refetch: refetchSession } = useSession();

	const {
		control,
		formState: { isDirty, isValid },
		handleSubmit,
		reset,
	} = useForm<FormType>();

	const [group, setGroup] = useState(initialGroup);
	const [hasPermissionChanged, setHasPermissionChanged] = useState(false);
	const [errorMessage, setErrorMessage] = useState<string>();

	const { isAdministrator = false, isDefault = false } = group ?? {};

	const { data: internalGroupData, loading: isInternalGroupFetchLoading } = useQuery<FetchInternalGroupType>(
		FETCH_INTERNAL_GROUP,
		{
			skip: !group,
			variables: { internalGroupId: group?.id },
		},
	);

	const { permissionModules = [] } = useMemo(
		() => (internalGroupData?.internalGroup || {}) as GroupType,
		[internalGroupData?.internalGroup],
	);

	const [createInternalGroup, { loading: isCreateInternalGroupLoading }] = useMutation<CreateInternalGroupType>(
		CREATE_INTERNAL_GROUP,
		{
			onCompleted: ({ createInternalGroup: result }) => setGroup(result),
			onError: (error) =>
				setErrorMessage(
					generateErrorInformations({
						error,
						resource: 'createInternalGroup',
					}).message,
				),
		},
	);

	const [updateInternalGroup, { loading: isUpdateInternalGroupLoading }] = useMutation<UpdateInternalGroupType>(
		UPDATE_INTERNAL_GROUP,
		{
			onCompleted: ({ updateInternalGroup: result }) => setGroup(result),
			onError: (error) =>
				setErrorMessage(
					generateErrorInformations({
						error,
						resource: 'updateInternalGroup',
					}).message,
				),
		},
	);

	const [removeInternalGroup, { loading: isRemoveInternalGroupLoading }] = useMutation(REMOVE_INTERNAL_GROUP, {
		onCompleted: back,
	});

	const isLoading = isCreateInternalGroupLoading || isUpdateInternalGroupLoading;

	const isMutationButtonDisabled = isRemoveInternalGroupLoading || !isValid || !isDirty;

	const handlePermissionChanged = useCallback(() => setHasPermissionChanged(true), []);

	const onSubmit = useCallback(
		(values: FormType) =>
			group
				? updateInternalGroup({
						variables: {
							updateInternalGroupInput: {
								id: group.id,
								...values,
							},
						},
				  })
				: createInternalGroup({
						variables: {
							createInternalGroupInput: {
								...values,
							},
						},
				  }),
		[createInternalGroup, group, updateInternalGroup],
	);

	const deleteInternalGroup = useCallback(async () => {
		await removeInternalGroup({
			variables: {
				removeInternalGroupId: group?.id,
			},
		});
	}, [removeInternalGroup, group]);

	const disabledTooltipContent = useMemo(() => {
		if (isAdministrator) return 'Vous ne pouvez pas modifier le groupe administrateur.';
		if (isDefault) return 'Vous ne pouvez pas modifier le groupe par défaut.';

		return undefined;
	}, [isAdministrator, isDefault]);

	useEffect(() => setGroup((previous) => internalGroupData?.internalGroup ?? previous), [internalGroupData]);

	useEffect(
		() =>
			reset({
				name: group?.name,
				isDefault: group?.isDefault,
			}),
		[reset, group],
	);

	useEffect(
		() => () => {
			hasPermissionChanged &&
				refetchSession().catch((e) => {
					throw e;
				});
		},
		[hasPermissionChanged, refetchSession],
	);

	return (
		<SettingsDialogPage title={group?.name ?? "Ajout d'un groupe"}>
			<SettingsGroup>
				<Controller
					name="name"
					control={control}
					render={({ field, fieldState: { error } }) => (
						<SettingsItemTextField
							{...field}
							description="Nom du groupe."
							disabled={isAdministrator || isInternalGroupFetchLoading}
							invalid={!!error}
							label="Nom"
							required
						/>
					)}
					rules={{ required: true }}
				/>

				<Controller
					name="isDefault"
					control={control}
					defaultValue={false}
					render={({ field: { value, ...field } }) => (
						<SettingsItemCheckbox
							{...field}
							checked={value as boolean}
							description="Si coché, le groupe est celui par défaut."
							disabled={isAdministrator || isDefault || isInternalGroupFetchLoading}
							disabledTooltipContent={disabledTooltipContent}
							label="Par défaut"
						/>
					)}
				/>
			</SettingsGroup>

			{!isAdministrator && (
				<Stack flexDirection="row-reverse" justifyContent="space-between">
					<Button
						disabled={isMutationButtonDisabled}
						onClick={handleSubmit(onSubmit)}
						startIcon={<Icon path={group ? mdiPencil : mdiPlus} />}
						variant="contained"
					>
						{group ? 'Modifier' : 'Ajouter'}
					</Button>

					{!isAdministrator && group && (
						<Tooltip
							content={
								isDefault ? 'Vous ne pouvez pas supprimer un groupe configuré comme groupe par défaut' : undefined
							}
							placement="top"
						>
							<div>
								<ConfirmationButton
									confirmLabel="Supprimer"
									color="error"
									message="Les utilisateurs attachés à ce groupe seront attachés au groupe par défaut."
									title={`Êtes-vous sûr de vouloir supprimer le groupe ${group.name ?? ''} ?`}
									disabled={isDefault || isLoading}
									variant="outlined"
									onConfirm={deleteInternalGroup}
								/>
							</div>
						</Tooltip>
					)}
				</Stack>
			)}

			{group && <UsersByGroupTable groupId={group.id} />}

			<InternalGroupPermissionModules
				permissionModules={permissionModules}
				disabled={isAdministrator}
				onPermissionChanged={handlePermissionChanged}
			/>

			{errorMessage && (
				<ErrorWrapper>
					<Icon path={mdiAlertCircle} />
					<Typography>{errorMessage}</Typography>
				</ErrorWrapper>
			)}
		</SettingsDialogPage>
	);
};

export default InternalGroupForm;
