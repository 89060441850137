import { ApolloError, useMutation } from '@apollo/client';
import { Button, Icon } from '@elipssolution/harfang';
import { mdiEyeOutline } from '@mdi/js';
import { styled } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';

import AddedUsersTableSection from './AddedUsersTableSection';
import ExternalUserForm from './ExternalUserForm';
import UserCustomerFilesTableSection from './UserCustomerFilesTableSection';
import PermissionWall from '../../../../components/PermissionWall';
import SettingsDialogPage from '../../../../components/SettingsDialogPage';
import { useSettingsDialog } from '../../../../hooks/useSettingsDialog';
import { PermissionEnum } from '../../../../types/permission';
import { generateErrorInformations } from '../../../../utils/errorHandler';
import { REMOVE_EXTERNAL_USER } from '../../../api/externalUser';
import {
	CREATE_EXTERNAL_USER_WITH_CUSTOMER_FILES,
	CreateExternalUserWithCustomerFilesType,
} from '../../../api/externalUserCustomerFile';
import { useSession } from '../../../components/SessionProvider';
import { AddExternalUserRelationWithCustomerFilesType, ExternalUserType, UserTypeEnum } from '../../../types/user';
import { useUserCustomerFileRelationContext } from '../../UserCustomerFileRelationProvider';

const ErrorWrapper = styled('div')(({ theme }) => ({
	height: 36,
	width: '50%',
	display: 'grid',
	placeItems: 'center',
	color: theme.palette.error.main,
	backgroundColor: `${theme.palette.error.main}1A`,
	borderRadius: theme.shape.borderRadius * 2,
}));

type ExternalUserProps = {
	user?: ExternalUserType;
};

const ExternalUser = ({ user: initialUser }: ExternalUserProps) => {
	const {
		checkPermission,
		impersonatedUser,
		impersonate,
		user: { type },
	} = useSession();
	const { back } = useSettingsDialog();
	const { userCustomerFileRelations, setUserCustomerFileRelations } = useUserCustomerFileRelationContext();

	const [error, setError] = useState<string>();
	const [relationsToAdd, setRelationsToAdd] = useState(
		userCustomerFileRelations as AddExternalUserRelationWithCustomerFilesType[],
	);
	const [user, setUser] = useState(initialUser);

	const [createExternalUserWithCustomerFiles] = useMutation<CreateExternalUserWithCustomerFilesType>(
		CREATE_EXTERNAL_USER_WITH_CUSTOMER_FILES,
		{
			onError: (createError) =>
				setError(
					generateErrorInformations({ error: createError, resource: 'createExternalUserWithCustomerFiles' }).message,
				),
		},
	);

	const [removeExternalUser] = useMutation(REMOVE_EXTERNAL_USER, {
		onError: (mutationError) => {
			setError(generateErrorInformations({ error: mutationError, resource: 'removeExternalUser' }).message);
		},
	});

	const handleSave = useCallback(
		({ user: { firstName, lastName, email }, customerFiles }: AddExternalUserRelationWithCustomerFilesType) => {
			createExternalUserWithCustomerFiles({
				variables: {
					createExternalUserWithCustomerFilesInput: {
						customerFilesWithGroup: customerFiles.map(({ customerFile, group }) => ({
							customerFileId: customerFile.id,
							groupId: group?.id,
						})),
						externalUser: {
							email,
							firstName,
							lastName,
						},
					},
				},
			})
				.then(({ data }) => {
					if (!data) return;

					const { externalUser } = data.createExternalUserWithCustomerFiles;

					setRelationsToAdd((prevRelations) => [...prevRelations, { user: externalUser, customerFiles }]);
				})
				.catch((mutationError: ApolloError) =>
					setError(
						generateErrorInformations({ error: mutationError, resource: 'createExternalUserWithCustomerFiles' })
							.message,
					),
				);
		},
		[createExternalUserWithCustomerFiles],
	);

	const removeUser = useCallback(
		async (userId: AddExternalUserRelationWithCustomerFilesType['user']['id']) => {
			await removeExternalUser({
				variables: { id: userId },
			});

			setRelationsToAdd((prevRelations) => {
				const relationsToAddFiltered = prevRelations.filter(({ user: { id } }) => id !== userId);

				setUserCustomerFileRelations(relationsToAddFiltered);

				return relationsToAddFiltered;
			});
		},
		[removeExternalUser, setUserCustomerFileRelations],
	);

	const handleUserUpdate = (updatedUser: ExternalUserType) => setUser(updatedUser);

	const handleBack = useCallback(() => {
		if (userCustomerFileRelations.length > 0) {
			setUserCustomerFileRelations([]);
		}
		back();
	}, [back, setUserCustomerFileRelations, userCustomerFileRelations.length]);

	useEffect(() => {
		if (relationsToAdd.length > 0) {
			setUserCustomerFileRelations(relationsToAdd);
		}
	}, [relationsToAdd, setUserCustomerFileRelations]);

	return (
		<SettingsDialogPage
			title={`${user?.firstName ?? ''} ${user?.lastName ?? ''}`.trim() || "Ajout d'utilisateurs"}
			titleActionButton={
				user &&
				!impersonatedUser &&
				checkPermission(PermissionEnum.IMPERSONATE) && (
					<Button
						variant="outlined"
						color="inherit"
						onClick={() => {
							impersonate({ id: user.id, email: user.email, lastName: user.lastName, firstName: user.firstName }).catch(
								(impersonateError) => {
									throw impersonateError;
								},
							);
						}}
						startIcon={<Icon path={mdiEyeOutline} />}
					>
						Voir en tant que
					</Button>
				)
			}
			onBack={handleBack}
		>
			<ExternalUserForm onError={setError} user={user} onSave={handleSave} onUserUpdate={handleUserUpdate} />

			{user ? (
				<PermissionWall
					permissionCodes={type === UserTypeEnum.INTERNAL ? [PermissionEnum.EXTERNAL_CUSTOMER_FILE_USER_MANAGE] : []}
				>
					<UserCustomerFilesTableSection user={user} />
				</PermissionWall>
			) : (
				<AddedUsersTableSection users={relationsToAdd} onRemoveUser={removeUser} />
			)}

			{error && <ErrorWrapper>{error}</ErrorWrapper>}
		</SettingsDialogPage>
	);
};

export default ExternalUser;
