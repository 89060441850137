import { NetworkStatus, useLazyQuery } from '@apollo/client';
import { Sidebar, useAutocomplete } from '@elipssolution/harfang';
import { mdiDomain } from '@mdi/js';
import Icon from '@mdi/react';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';

import { useApollo } from './ApolloProvider';
import { useSession } from './SessionProvider';
import { CustomerFileType } from '../../types/customerFile';
import { FETCH_CUSTOMER_FILES, FetchCustomerFilesType } from '../api/customerFile';

const SidebarCustomerFileSelector = () => {
	const {
		changeCustomerFile: changeSessionCustomerFile,
		customerFile: sessionCustomerFile,
		refetch: refetchSession,
	} = useSession();
	const { refetchActiveQueries } = useApollo();
	const { push } = useRouter();

	// This state is volontary redundant with the customerFile of session because we need to display the selected user before the session refetch.
	const [customerFile, setCustomerFile] = useState<CustomerFileType>();
	const sidebarCustomerFileAutocompleteInstance = useAutocomplete();

	const [fetchCustomerFiles, { networkStatus }] = useLazyQuery<FetchCustomerFilesType>(FETCH_CUSTOMER_FILES, {
		notifyOnNetworkStatusChange: true,
		onCompleted: ({ customerFiles: { count } }) => {
			if (!count && !sessionCustomerFile) {
				push('/error/emptyCustomerFiles').catch((error) => {
					throw error;
				});
			}
		},
	});

	const customerFileDataSource = useCallback(
		async (
			limit: number,
			offset: number,
			search?: string,
		): Promise<{
			items: CustomerFileType[];
			count: number;
		}> => {
			const { data: { customerFiles } = {}, error } = await fetchCustomerFiles({
				variables: {
					page: {
						limit,
						offset,
					},
					search,
				},
			});

			if (error) {
				throw error;
			}

			const { count = 0, items = [] } = customerFiles ?? {};

			return {
				count,
				items,
			};
		},
		[fetchCustomerFiles],
	);

	const handleCustomerFileChange = (selectedCustomerFile?: CustomerFileType) => {
		if (!selectedCustomerFile) {
			throw new Error("Customer file can't be cleared");
		}

		changeSessionCustomerFile(selectedCustomerFile).catch((e) => {
			throw e;
		});
		setCustomerFile(selectedCustomerFile);
	};

	useEffect(() => {
		if (sessionCustomerFile) {
			setCustomerFile(sessionCustomerFile);
		}
	}, [sessionCustomerFile]);

	// Handling navigating from customerFile-not-found error to reset the selected customerFile
	useEffect(() => {
		const storage = globalThis.sessionStorage;

		const prevPath = storage.getItem('prevPath');

		if (prevPath?.includes('/error/404?error=customerFile-not-found')) {
			(async () => {
				await refetchSession();

				await refetchActiveQueries();
			})().catch((error) => {
				throw error;
			});
		}
	}, [refetchSession, refetchActiveQueries]);

	useEffect(() => {
		networkStatus === NetworkStatus.refetch && sidebarCustomerFileAutocompleteInstance.reload();
	}, [networkStatus, sidebarCustomerFileAutocompleteInstance]);

	return (
		<Sidebar.Autocomplete<CustomerFileType>
			autocomplete={sidebarCustomerFileAutocompleteInstance}
			placeholder="Aucune entreprise"
			dataSource={customerFileDataSource}
			getOptionLabel={({ code, name }) => `${code} - ${name}`}
			icon={<Icon path={mdiDomain} />}
			onChange={handleCustomerFileChange}
			optionWidth={300}
			title="Dossiers clients"
			value={customerFile}
			disableClearable
		/>
	);
};

export default SidebarCustomerFileSelector;
