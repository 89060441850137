import {
	Table,
	TableAutocompleteFilterDefinitionType,
	TableColumnType,
	TableFilterType,
	TableGenericFilterInputType,
	TableInstance,
	TableOrderByType,
} from '@elipssolution/harfang';
import { useMemo } from 'react';

import useTemplateDataSource from './hooks/useTemplateDataSource';
import useVoucherUserDataSource from './hooks/useVoucherUserDataSource';
import { CustomerFileTemplateType } from '../../types/template';
import { VoucherFilterType, VoucherType, VoucherUserType } from '../../types/voucher';

type TemplateFilterValueType = Required<TableAutocompleteFilterDefinitionType<CustomerFileTemplateType>['output']>;

type VoucherTableProps = {
	columns: TableColumnType<VoucherType>[];
	dataSource: (
		limit: number,
		offset: number,
		search?: string,
		orderBy?: TableOrderByType<VoucherType>,
		filter?: TableFilterType<VoucherFilterType>,
	) => Promise<{ items: VoucherType[]; count: number }>;
	onVoucherSelection: (voucher: VoucherType) => void;
	onTemplateFilterChange?: (filter?: TemplateFilterValueType) => void;
	tableInstance: TableInstance;
	templateFilterValue?: TemplateFilterValueType;
	title: string;
};

const VoucherTable = ({
	columns,
	dataSource,
	onTemplateFilterChange,
	onVoucherSelection,
	tableInstance,
	templateFilterValue,
	title,
}: VoucherTableProps) => {
	const { dataSource: templatesDataSource } = useTemplateDataSource();

	const { dataSource: voucherUsersDataSource } = useVoucherUserDataSource();

	const filters: TableGenericFilterInputType<VoucherFilterType> = useMemo(
		() => ({
			template: {
				label: 'Modèle',
				type: 'autocomplete',
				dataSource: templatesDataSource,
				getOptionLabel: ({ name }: CustomerFileTemplateType) => name,
				onChange: (filterValue?: TemplateFilterValueType) => onTemplateFilterChange?.(filterValue ?? undefined),
				value: templateFilterValue,
			},
			accountingDate: {
				label: 'Période de comptabilisation',
				type: 'date',
			},
			amount: {
				label: 'Montant',
				type: 'currency',
			},
			createdBy: {
				label: 'Créé par',
				type: 'autocomplete',
				dataSource: voucherUsersDataSource,
				getOptionLabel: ({ email }: VoucherUserType) => email,
			},
		}),
		[onTemplateFilterChange, templatesDataSource, voucherUsersDataSource, templateFilterValue],
	);

	return (
		<Table<VoucherType, VoucherFilterType>
			columns={columns}
			dataSource={dataSource}
			filter={onTemplateFilterChange ? filters : undefined}
			onRowClick={onVoucherSelection}
			style={{
				height: '100%',
			}}
			table={tableInstance}
			title={title}
			enableSearch={!!onTemplateFilterChange}
		/>
	);
};

export default VoucherTable;
