import { useTreeTable } from '@elipssolution/harfang';
import { Stack } from '@mui/material';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';
import { FiscalYearType } from '../../../../types/fiscalYear';
import { convertFiscalYearDates } from '../../../../utils/convertDates';
import { AnalyticalType } from '../../../quickentry/types/analytical';
import { AccountingType } from '../../types/accounting';
import { DecimalFilter, SwitchOperatorType } from '../../types/table';
import AnalyticalSectionsTable from './AnalyticalSectionsTable';
import AnalyticalSectionsToggles from './AnalyticalSectionsToggles';

const AnalyticalSections = () => {
	const tableInstance = useTreeTable();
	const { events } = useRouter();

	const {
		balanceFilter: queryBalanceFilter,
		selectedFiscalYear: querySelectedFiscalYear,
		selectedAnalyticalDimensionId: querySelectedAnalyticalDimensionId,
		selectedDateRange: querySelectedDateRange,
		accountingType: queryAccountingType,
		isGroupedByAccount: isQueryGroupedByAccount,
		expandedLevels: queryExpandedLevels,
	} = useMemo(() => {
		const storedFilters = globalThis.sessionStorage.getItem('analyticalSectionsFilters');

		if (storedFilters) {
			const {
				balanceFilter,
				selectedFiscalYear,
				selectedAnalyticalDimensionId,
				selectedDateRange,
				accountingType,
				isGroupedByAccount,
				expandedLevels,
			} = JSON.parse(storedFilters) as {
				balanceFilter?: DecimalFilter;
				selectedFiscalYear?: FiscalYearType;
				selectedAnalyticalDimensionId?: AnalyticalType;
				selectedDateRange?: [Date, Date];
				accountingType?: AccountingType;
				isGroupedByAccount: boolean;
				expandedLevels: string[][];
			};

			return {
				balanceFilter,
				selectedFiscalYear: selectedFiscalYear ? convertFiscalYearDates(selectedFiscalYear) : undefined,
				selectedAnalyticalDimensionId,
				selectedDateRange: selectedDateRange?.map((item) => new Date(item)) as [Date, Date],
				accountingType,
				isGroupedByAccount,
				expandedLevels,
			};
		}

		return {};
	}, []);

	const [expandedLevels, setExpandedLevels] = useState<string[][]>(queryExpandedLevels || []);
	const [selectedFiscalYear, setSelectedFiscalYear] = useState<FiscalYearType | undefined>(querySelectedFiscalYear);
	const [selectedAnalyticalDimension, setSelectedAnalyticalDimension] = useState<AnalyticalType | undefined>(
		querySelectedAnalyticalDimensionId,
	);
	const [selectedDateRange, setSelectedDateRange] = useState<[Date, Date] | undefined>(querySelectedDateRange);
	const [balanceFilter, setBalanceFilter] = useState<DecimalFilter | undefined>(queryBalanceFilter);
	const [accountingType, setAccountingType] = useState<AccountingType>(
		queryAccountingType ?? AccountingType.INCOME_STATEMENT_PROFIT_AND_LOSS_ACCOUNT,
	);
	const [isGroupedByAccount, setIsGroupedByAccount] = useState(isQueryGroupedByAccount ?? true);
	const [filterValues, setFilterValues] = useState<DecimalFilter | undefined>();

	const handleBalanceFilterOperatorChange = (operator: SwitchOperatorType) => {
		setBalanceFilter(!balanceFilter?.[operator] ? { [operator]: '0.00' } : undefined);
	};

	const handleChangeAccountingType = (type: AccountingType) => {
		setAccountingType(type);
	};

	const handleGroupedByAccountChange = (groupedByAccount: boolean) => {
		setIsGroupedByAccount(groupedByAccount);
		setExpandedLevels([]);
	};

	useEffect(() => {
		globalThis.sessionStorage.setItem(
			'analyticalSectionsFilters',
			JSON.stringify({
				balanceFilter,
				selectedFiscalYear,
				selectedDateRange,
				accountingType,
				isGroupedByAccount,
				expandedLevels,
			}),
		);
	}, [accountingType, balanceFilter, selectedDateRange, selectedFiscalYear, isGroupedByAccount, expandedLevels]);

	useEffect(() => {
		events.on('routeChangeStart', (newRoute: string) => {
			!newRoute.startsWith('/accounting/analyticalSections') &&
				globalThis.sessionStorage.removeItem('analyticalSectionsFilters');
		});
	}, [events]);

	return (
		<Stack height="100%" width="100%" gap={4} flexDirection="row">
			<AnalyticalSectionsToggles
				accountingType={accountingType}
				selectedFiscalYear={selectedFiscalYear}
				selectedAnalyticalDimension={selectedAnalyticalDimension}
				selectedDateRange={selectedDateRange}
				balanceFilter={balanceFilter}
				isGroupedByAccount={isGroupedByAccount}
				onAccountingTypeChange={handleChangeAccountingType}
				onFiscalYearChange={setSelectedFiscalYear}
				onAnalyticalDimensionChange={setSelectedAnalyticalDimension}
				onDateRangeSelection={setSelectedDateRange}
				onBalanceFilterOperatorChange={handleBalanceFilterOperatorChange}
				onIsGroupedByAccountChange={handleGroupedByAccountChange}
				tableInstance={tableInstance}
			/>

			<AnalyticalSectionsTable
				isGroupedByAccount={isGroupedByAccount}
				accountType={accountingType}
				selectedFiscalYear={selectedFiscalYear}
				selectedAnalyticalDimension={selectedAnalyticalDimension}
				selectedDateRange={selectedDateRange}
				balanceFilter={balanceFilter}
				selectedFilterValues={filterValues}
				onBalanceFilterChange={setBalanceFilter}
				onFilterValuesChange={setFilterValues}
				expandedLevels={expandedLevels}
				onToggleRows={setExpandedLevels}
				tableInstance={tableInstance}
			/>
		</Stack>
	);
};

export default AnalyticalSections;
