import { useLazyQuery } from '@apollo/client';
import {
	Chip,
	TableAutocompleteFilterDefinitionType,
	TableColumnType,
	TableFilterType,
	TableGenericFilterInputType,
	TableOrderByType,
	TableSelectFilterDefinitionType,
} from '@elipssolution/harfang';
import { useCallback, useMemo } from 'react';

import LinkIcon from '../../../../components/LinkIcon';
import SettingsTable from '../../../../components/SettingsTable';
import { CustomerFileWithDomainTypeAndIsDashboardInherited } from '../../../../types/customerFile';
import { DomainType } from '../../../../types/domain';
import {
	FETCH_CUSTOMER_FILES_WITH_DOMAIN_AND_IS_DASHBOARD_INHERITED,
	FetchCustomerFilesWithDomainAndIsDashboardInheritedType,
} from '../../../api/customerFile';
import { FETCH_USED_DOMAINS, FetchUsedDomainsType } from '../../../api/domain';

export type WidgetCustomerFilesFilterType = {
	isDashboardInherited: TableSelectFilterDefinitionType<boolean>;
	domainId: TableAutocompleteFilterDefinitionType<DomainType>;
};

const columns: TableColumnType<CustomerFileWithDomainTypeAndIsDashboardInherited>[] = [
	{
		title: 'Niveau',
		key: 'isInherited',
		field: 'isDashboardInherited',
		render: ({ isDashboardInherited }) => (
			<Chip
				label={isDashboardInherited ? 'Domaine' : 'Dossier'}
				color={isDashboardInherited ? '#5cadab' : '#720e9e'}
				style={{ width: 80 }}
			/>
		),
		width: 144,
		flexGrow: 0,
	},
	{
		field: 'code',
		flexGrow: 0,
		key: 'code',
		sortable: true,
		title: 'Code',
		width: 100,
	},
	{
		field: 'name',
		key: 'name',
		sortable: true,
		title: 'Nom du dossier',
		width: 200,
	},
	{
		key: 'chip',
		flexGrow: 0,
		render: ({ domain: { isDefault, name } }) => <Chip label={name} color={isDefault ? 'info' : 'default'} />,
		width: 110,
	},
	{
		flexGrow: 0,
		key: 'actions',
		render: () => <LinkIcon />,
		width: 40,
	},
];

type CustomerFilesTableSectionProps = {
	onCustomerFileSelection?: (customerFile: CustomerFileWithDomainTypeAndIsDashboardInherited) => void;
};

const CustomerFilesTableSection = ({ onCustomerFileSelection }: CustomerFilesTableSectionProps) => {
	const [fetchCustomerFiles] = useLazyQuery<FetchCustomerFilesWithDomainAndIsDashboardInheritedType>(
		FETCH_CUSTOMER_FILES_WITH_DOMAIN_AND_IS_DASHBOARD_INHERITED,
	);

	const dataSource = useCallback(
		async (
			limit: number,
			offset: number,
			search?: string,
			orderBy?: TableOrderByType<CustomerFileWithDomainTypeAndIsDashboardInherited>,
			filter?: TableFilterType<WidgetCustomerFilesFilterType>,
		): Promise<{ count: number; items: CustomerFileWithDomainTypeAndIsDashboardInherited[] }> => {
			const { field, order } = orderBy || {};

			const { data, error } = await fetchCustomerFiles({
				variables: {
					...(orderBy && { orderBy: { field, order } }),
					page: {
						limit,
						offset,
					},
					search,
					filter: { isDashboardInherited: filter?.isDashboardInherited, domainId: { eq: filter?.domainId?.eq?.id } },
				},
			});

			if (error) {
				throw error;
			}

			const {
				customerFilesWithIsDashboardInherited: { count = 0, items = [] },
			} = data ?? {
				customerFilesWithIsDashboardInherited: {},
			};

			return {
				count,
				items,
			};
		},
		[fetchCustomerFiles],
	);

	const [fetchUsedDomains] = useLazyQuery<FetchUsedDomainsType>(FETCH_USED_DOMAINS);

	const usedDomainsDataSource = useCallback(
		async (
			limit: number,
			offset: number,
			search?: string,
		): Promise<{
			count: number;
			items: DomainType[];
		}> => {
			const { data, error } = await fetchUsedDomains({
				variables: {
					page: {
						limit,
						offset,
					},
					search,
				},
			});

			if (error) {
				throw error;
			}

			const {
				usedDomains: { count = 0, items = [] },
			} = data ?? {
				usedDomains: {},
			};

			return {
				count,
				items,
			};
		},
		[fetchUsedDomains],
	);
	const filters: TableGenericFilterInputType<WidgetCustomerFilesFilterType> = useMemo(
		() => ({
			isDashboardInherited: {
				label: 'Hérité du domaine',
				type: 'select',
				options: [true, false],
				renderOption: (value) => (value ? 'Oui' : 'Non'),
				renderValue: (value) => (value ? 'Oui' : 'Non'),
			},
			domainId: {
				type: 'autocomplete',
				label: 'Domaine',
				dataSource: usedDomainsDataSource,
				getOptionLabel: ({ name }: DomainType) => name,
			},
		}),
		[usedDomainsDataSource],
	);

	return (
		<SettingsTable<CustomerFileWithDomainTypeAndIsDashboardInherited, WidgetCustomerFilesFilterType>
			addButtonLabel={null}
			dataSource={dataSource}
			onRowClick={onCustomerFileSelection}
			tableColumns={columns}
			filter={filters}
			title="Dossiers clients"
		/>
	);
};

export default CustomerFilesTableSection;
