import { ApolloError, useMutation, useQuery } from '@apollo/client';
import { Button, ConfirmationDialog, DialogProps, Icon } from '@elipssolution/harfang';
import { mdiPencil } from '@mdi/js';
import { Stack, styled } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { v4 as uuid } from 'uuid';

import StorageDownloadConfigForm from './StorageDownloadConfigForm';
import SettingsDialogPage from '../../../../components/SettingsDialogPage';
import { useSettingsDialog } from '../../../../hooks/useSettingsDialog';
import { generateErrorInformations } from '../../../../utils/errorHandler';
import {
	CREATE_STORAGE_DOWNLOAD,
	CreateStorageDownloadType,
	FETCH_STORAGE_DOWNLOAD,
	FetchStorageDownloadType,
	REMOVE_STORAGE_DOWNLOAD,
	UPDATE_STORAGE_DOWNLOAD,
	UpdateStorageDownloadType,
} from '../../api/settingStorageDownload';
import ConnectorForm, { ConnectorFormType } from '../../components/ConnectorForm';
import { ConnectorType } from '../../types/connector';
import { SettingStorageDownloadType } from '../../types/settingStorage';
import { StorageDownloadFormType } from '../../types/storageDownloadFormType';

const ErrorWrapper = styled('div')(({ theme: { palette, shape } }) => ({
	height: 36,
	width: '50%',

	display: 'grid',
	placeItems: 'center',

	color: palette.error.main,
	backgroundColor: `${palette.error.main}1A`,
	borderRadius: shape.borderRadius * 2,
}));

const StorageDownload = () => {
	const { back } = useSettingsDialog();

	const {
		control,
		formState: { isDirty, isValid },
		handleSubmit,
		reset,
		watch,
		setValue,
	} = useForm<StorageDownloadFormType>();

	const [storageDownloadId, setStorageDownloadId] = useState<string>();
	const [error, setError] = useState<string>();
	const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);
	const [confirmationDialogError, setConfirmationDialogError] = useState<string>();
	const [connectorFormValues, setConnectorFormValues] = useState<ConnectorFormType>();
	const [isConfigurationValid, setIsConfigurationValid] = useState(false);
	const [connector, setConnector] = useState<ConnectorType>();

	const openConfirmationDialog = useCallback(() => setIsConfirmationDialogOpen(true), []);

	const closeConfirmationDialog = useCallback(() => {
		setIsConfirmationDialogOpen(false);
		setConfirmationDialogError(undefined);
	}, []);

	const applyStorageDownloadToForm = useCallback(
		({
			connector: storageDownloadConnector,
			exclusions,
			levelName1,
			levelName2,
			levelName3,
			folders,
		}: SettingStorageDownloadType) => {
			if (storageDownloadConnector) {
				reset({
					...(exclusions && {
						exclusions: (JSON.parse(exclusions ?? '[]') as string[]).map((exclusion) => ({
							id: uuid(),
							value: exclusion,
						})),
					}),
					levelName1,
					levelName2,
					levelName3,
					folders: JSON.parse(folders ?? '{}') as Record<string, string>,
				});
			}
		},
		[reset],
	);

	const { loading: isStorageDownloadFetchLoading, data: storageDownloadData } = useQuery<FetchStorageDownloadType>(
		FETCH_STORAGE_DOWNLOAD,
		{
			onCompleted: ({ document_settingStorageDownload }) => {
				const { id } = document_settingStorageDownload ?? {};
				setStorageDownloadId(id);

				if (document_settingStorageDownload !== null) applyStorageDownloadToForm(document_settingStorageDownload);
			},
			onError: (fetchError) =>
				setError(generateErrorInformations({ error: fetchError, resource: 'document_settingStorageDownload' }).message),
		},
	);

	const [createStorageDownload, { loading: isCreateStorageDownloadLoading }] = useMutation<CreateStorageDownloadType>(
		CREATE_STORAGE_DOWNLOAD,
		{
			onCompleted: ({ document_createSettingStorageDownload: { id } }) => setStorageDownloadId(id),
		},
	);

	const [updateStorageDownload, { loading: isUpdateStorageDownloadLoading }] =
		useMutation<UpdateStorageDownloadType>(UPDATE_STORAGE_DOWNLOAD);

	const [removeStorageDownload, { loading: isRemoveStorageDownloadLoading }] = useMutation(REMOVE_STORAGE_DOWNLOAD, {
		onCompleted: back,
		onError: (mutationError) =>
			setConfirmationDialogError(
				generateErrorInformations({ error: mutationError, resource: 'document_removeSettingStorageDownload' }).message,
			),
	});

	const connectorInitialFormValues = useMemo(
		() =>
			storageDownloadData?.document_settingStorageDownload?.connector
				? {
						configuration: JSON.parse(
							storageDownloadData?.document_settingStorageDownload.configuration ?? '{}',
						) as Record<string, string>,
						connector: storageDownloadData?.document_settingStorageDownload.connector,
				  }
				: undefined,
		[storageDownloadData],
	);

	const isLoading = useMemo(
		() =>
			isStorageDownloadFetchLoading ||
			isCreateStorageDownloadLoading ||
			isUpdateStorageDownloadLoading ||
			isRemoveStorageDownloadLoading,
		[
			isStorageDownloadFetchLoading,
			isCreateStorageDownloadLoading,
			isUpdateStorageDownloadLoading,
			isRemoveStorageDownloadLoading,
		],
	);

	const isMutationButtonDisabled = useMemo(
		() => isLoading || !isValid || !(isConfigurationValid || isDirty),
		[isConfigurationValid, isDirty, isLoading, isValid],
	);

	const handleRemoval = useCallback(
		() =>
			removeStorageDownload({
				variables: {
					id: storageDownloadId,
				},
			}),
		[removeStorageDownload, storageDownloadId],
	);

	const onSubmit = useCallback(
		(values: StorageDownloadFormType) => {
			const { exclusions, folders, ...restValues } = values;

			const { configuration, connector: connectorValue } = connectorFormValues ?? {};
			const toSend = {
				configuration: JSON.stringify(configuration ?? {}),
				folders: JSON.stringify(folders ?? {}),
				connector: connectorValue,
				...restValues,
				...(exclusions && {
					exclusions: JSON.stringify(exclusions.map(({ value }) => value)),
				}),
			};

			const modeMutationInfoMap = new Map([
				[
					'create',
					{ mutation: createStorageDownload, mutationName: 'createSettingStorageDownload', inputValues: toSend },
				],
				[
					'update',
					{
						mutation: updateStorageDownload,
						mutationName: 'updateSettingStorageDownload',
						inputValues: { ...toSend, id: storageDownloadId },
					},
				],
			]);

			const {
				mutation,
				mutationName = '',
				inputValues,
			} = modeMutationInfoMap.get(storageDownloadId ? 'update' : 'create') ?? {};

			return mutation?.({
				variables: {
					[`${mutationName}Input`]: inputValues,
				},
			})
				.then(() => {
					setError(undefined);

					reset(values);
				})
				.catch((mutationError: ApolloError) =>
					setError(generateErrorInformations({ error: mutationError, resource: 'settingStorageDownload' }).message),
				);
		},
		[createStorageDownload, reset, updateStorageDownload, connectorFormValues, storageDownloadId],
	);
	const actionsDialog = useMemo(
		(): DialogProps['actionsDialog'] => [
			{
				disabled: isRemoveStorageDownloadLoading,
				label: 'Annuler',
				onClick: closeConfirmationDialog,
			},
			{
				color: 'error',
				label: 'Supprimer',
				loading: isRemoveStorageDownloadLoading,
				persistantErrorMessage: confirmationDialogError,
				onClick: handleRemoval,
				variant: 'contained',
			},
		],
		[closeConfirmationDialog, confirmationDialogError, handleRemoval, isRemoveStorageDownloadLoading],
	);

	const validateRequiredFolders = (value?: Record<string, string | undefined> | null): boolean => {
		if (!connector) return false;

		const { folders } = connector;
		const requiredFoldersCodes: string[] = folders?.flatMap(({ code, isRequired }) => (isRequired ? [code] : [])) ?? [];

		return requiredFoldersCodes.every((code) => value?.[code] !== '' && value?.[code] !== '/');
	};

	useEffect(() => connector && setValue('folders', null), [connector, setValue]);

	return (
		<SettingsDialogPage title="Consultation GED">
			<ConnectorForm
				values={connectorFormValues}
				onValuesChange={setConnectorFormValues}
				onConnectorChange={setConnector}
				connector={connector}
				onConfigurationValidityChange={setIsConfigurationValid}
				initialValues={connectorInitialFormValues}
				type="download"
				hasStorageData={!!storageDownloadData && storageDownloadData?.document_settingStorageDownload !== null}
			/>

			{connector && (
				<StorageDownloadConfigForm
					control={control}
					watch={watch}
					connector={connector}
					validateRequiredFolders={validateRequiredFolders}
				/>
			)}

			<Stack flexDirection="row-reverse" justifyContent="space-between">
				<Button
					disabled={isMutationButtonDisabled}
					onClick={handleSubmit(onSubmit)}
					startIcon={<Icon path={mdiPencil} />}
					variant="contained"
				>
					{storageDownloadId ? 'Modifier' : 'Ajouter'}
				</Button>

				{error && <ErrorWrapper>{error}</ErrorWrapper>}

				{storageDownloadId && (
					<Button color="error" disabled={isLoading} onClick={openConfirmationDialog} variant="outlined">
						Supprimer
					</Button>
				)}
			</Stack>

			{storageDownloadId && (
				<ConfirmationDialog
					actionsDialog={actionsDialog}
					open={isConfirmationDialogOpen}
					onClose={closeConfirmationDialog}
					title="Êtes-vous sûr de vouloir supprimer l'emplacement de consultation ?"
				/>
			)}
		</SettingsDialogPage>
	);
};

export default StorageDownload;
