import { ApolloError, useMutation } from '@apollo/client';
import {
	Button,
	ConfirmationDialog,
	DialogProps,
	Icon,
	SettingsGroup,
	SettingsItemTextField,
	Tooltip,
	useTable,
} from '@elipssolution/harfang';
import { mdiEmailArrowRight, mdiPencil, mdiPlus } from '@mdi/js';
import { Stack } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import AddCustomerFilesTable from './AddCustomerFilesTable';
import { useSettingsDialog } from '../../../../hooks/useSettingsDialog';
import { CustomerFileWithGroup } from '../../../../types/customerFile';
import { generateErrorInformations } from '../../../../utils/errorHandler';
import {
	REMOVE_EXTERNAL_USER,
	SEND_EXTERNAL_USER_ACCOUNT_CONFIRMATION_MAIL,
	UPDATE_EXTERNAL_USER,
	UpdateExternalUserType,
} from '../../../api/externalUser';
import { useSession } from '../../../components/SessionProvider';
import { AddExternalUserRelationWithCustomerFilesType, ExternalUserType } from '../../../types/user';
import SettingsItemEmailField from '../../SettingsItemEmailField';

type FormType = {
	firstName?: ExternalUserType['firstName'];
	lastName?: ExternalUserType['lastName'];
	email?: ExternalUserType['email'];
};

type ExternalUserFormProps = {
	user?: ExternalUserType;
	onSave: (userWithCustomerFiles: AddExternalUserRelationWithCustomerFilesType) => void;
	onError: (error: string) => void;
	onUserUpdate: (updatedUser: ExternalUserType) => void;
};

const ExternalUserForm = ({ user, onSave, onError, onUserUpdate }: ExternalUserFormProps) => {
	const { back } = useSettingsDialog();
	const customerFileTableInstance = useTable();
	const { user: sessionUser } = useSession();
	const { id, lastName, firstName, email: initialUserEmail } = user ?? {};

	const {
		control,
		formState: { dirtyFields, isDirty, isValid },
		handleSubmit,
		reset,
	} = useForm<FormType>({
		mode: 'onChange',
	});

	const [isRemoveExternalUserError, setIsRemoveExternalUserError] = useState(false);
	const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);
	const [confirmationDialogError, setConfirmationDialogError] = useState<string>();
	const [selectedCustomerFiles, setSelectedCustomerFiles] = useState<CustomerFileWithGroup[]>([]);

	const isEmailFieldDirty = useMemo(() => !!dirtyFields.email, [dirtyFields.email]);

	const isUserSessionUser = useMemo(() => sessionUser.id === id, [id, sessionUser.id]);

	const openConfirmationDialog = useCallback(() => setIsConfirmationDialogOpen(true), []);

	const closeConfirmationDialog = useCallback(() => {
		setIsConfirmationDialogOpen(false);
		setConfirmationDialogError(undefined);
	}, []);

	const [updateExternalUser, { loading: isUpdateExternalUserLoading }] =
		useMutation<UpdateExternalUserType>(UPDATE_EXTERNAL_USER);

	const [removeExternalUser, { loading: isRemoveExternalUserLoading }] = useMutation(REMOVE_EXTERNAL_USER, {
		onCompleted: back,
		onError: (mutationError) => {
			setIsRemoveExternalUserError(true);
			onError(generateErrorInformations({ error: mutationError, resource: 'removeExternalUser' }).message);
		},
	});

	const [sendExternalUserAccountConfirmationMail] = useMutation(SEND_EXTERNAL_USER_ACCOUNT_CONFIRMATION_MAIL);

	const isLoading = useMemo(
		() => isRemoveExternalUserLoading || isUpdateExternalUserLoading,
		[isRemoveExternalUserLoading, isUpdateExternalUserLoading],
	);

	const isUserSelectedUserConnected = useMemo(() => id === sessionUser.id, [id, sessionUser]);

	const isMutationButtonDisabled = useMemo(() => isLoading || !isDirty || !isValid, [isLoading, isDirty, isValid]);

	const startIcon = useMemo(() => <Icon path={id ? mdiPencil : mdiPlus} />, [id]);

	const handleRemoval = useCallback(() => removeExternalUser({ variables: { id } }), [removeExternalUser, id]);

	const handleSendAccountConfirmationMail = useCallback(
		() => sendExternalUserAccountConfirmationMail({ variables: { externalUserId: id } }),
		[sendExternalUserAccountConfirmationMail, id],
	);
	const handleSelectedCustomerFilesChange = (newSelectedCustomerFiles: CustomerFileWithGroup[]) =>
		setSelectedCustomerFiles(newSelectedCustomerFiles);

	const onSubmit = useCallback(
		(values: FormType) => {
			const { firstName: formFirstName, lastName: formLastName = '', email: formEmail = '' } = values;

			if (!id) {
				onSave({
					user: {
						firstName: formFirstName,
						lastName: formLastName,
						email: formEmail,
					},
					customerFiles: selectedCustomerFiles,
				});

				reset({
					email: undefined,
					lastName: undefined,
					firstName: undefined,
				});

				handleSelectedCustomerFilesChange([]);
				customerFileTableInstance.reload();
			} else {
				updateExternalUser({
					variables: {
						updateExternalUserInput: {
							...values,
							firstName: formFirstName || null,
							id,
						},
					},
				})
					.then(() => {
						onUserUpdate({ ...user, ...values } as ExternalUserType);
					})
					.catch((mutationError: ApolloError) =>
						onError(generateErrorInformations({ error: mutationError, resource: 'updateExternalUser' }).message),
					);
			}
		},
		[
			id,
			onSave,
			selectedCustomerFiles,
			reset,
			customerFileTableInstance,
			updateExternalUser,
			onUserUpdate,
			user,
			onError,
		],
	);

	// Setting form state
	useEffect(
		() =>
			reset({
				email: initialUserEmail,
				lastName,
				firstName,
			}),
		[reset, initialUserEmail, lastName, firstName],
	);

	const actionsDialog = useMemo(
		(): DialogProps['actionsDialog'] => [
			{
				disabled: isRemoveExternalUserLoading,
				label: 'Annuler',
				onClick: closeConfirmationDialog,
			},
			{
				loading: isRemoveExternalUserLoading,
				error: isRemoveExternalUserError,
				color: 'error',
				label: 'Supprimer',
				onClick: handleRemoval,
				variant: 'contained',
			},
		],
		[closeConfirmationDialog, handleRemoval, isRemoveExternalUserError, isRemoveExternalUserLoading],
	);

	return (
		<>
			<SettingsGroup>
				<Controller
					control={control}
					render={({ field: { onChange, ...field } }) => {
						const handleUpperCaseChange = (value?: string) => {
							onChange(value?.toUpperCase());
						};

						return (
							<SettingsItemTextField
								{...field}
								description="Nom de l'utilisateur."
								label="Nom"
								onChange={handleUpperCaseChange}
								required
							/>
						);
					}}
					name="lastName"
					rules={{ required: true }}
				/>
				<Controller
					control={control}
					render={({ field: { onChange, ...field } }) => {
						const handleFirstLetterUpperCaseChange = (value?: string) => {
							onChange(value ? value.charAt(0).toUpperCase() + value.slice(1) : undefined);
						};

						return (
							<SettingsItemTextField
								{...field}
								description="Prénom de l'utilisateur."
								label="Prénom"
								onChange={handleFirstLetterUpperCaseChange}
							/>
						);
					}}
					name="firstName"
				/>
				<SettingsItemEmailField control={control} initialValue={initialUserEmail} disabled={isUserSessionUser} />
			</SettingsGroup>

			{!user && (
				<AddCustomerFilesTable
					selectedCustomerFiles={selectedCustomerFiles}
					onSelectedCustomerFilesChange={handleSelectedCustomerFilesChange}
					tableInstance={customerFileTableInstance}
				/>
			)}

			<Stack flexDirection="row-reverse" justifyContent="space-between">
				<Stack flexDirection="row" gap={1}>
					{id && !isUserSessionUser && (
						<Tooltip
							content={
								isEmailFieldDirty &&
								"Un changement est en cours sur l'adresse email. Vous devez valider le changement ou l'annuler avant de pouvoir renvoyer un email de confirmation à l'utilisateur"
							}
						>
							<div>
								<Button
									disabled={isEmailFieldDirty}
									onClick={handleSendAccountConfirmationMail}
									startIcon={<Icon path={mdiEmailArrowRight} />}
								>
									Renvoyer la confirmation
								</Button>
							</div>
						</Tooltip>
					)}

					<Button
						disabled={isMutationButtonDisabled}
						onClick={handleSubmit(onSubmit)}
						startIcon={startIcon}
						variant="contained"
					>
						{id ? 'Modifier' : 'Ajouter'}
					</Button>
				</Stack>

				{id && (
					<Tooltip
						content={isUserSelectedUserConnected ? 'Un utilisateur ne peut pas se supprimer lui même' : undefined}
					>
						<div>
							<Button
								color="error"
								disabled={isLoading || isUserSelectedUserConnected}
								onClick={openConfirmationDialog}
								variant="outlined"
							>
								Supprimer
							</Button>
						</div>
					</Tooltip>
				)}
			</Stack>

			{id && lastName && (
				<ConfirmationDialog
					actionsDialog={actionsDialog}
					error={confirmationDialogError}
					open={isConfirmationDialogOpen}
					onClose={closeConfirmationDialog}
					title={`Êtes-vous sûr de vouloir supprimer l'utilisateur ${`${firstName ?? ''} ${lastName}`.trim()} ?`}
				/>
			)}
		</>
	);
};

export default ExternalUserForm;
