import { ApolloError, useMutation } from '@apollo/client';
import {
	Button,
	ConfirmationDialog,
	DialogProps,
	Icon,
	SettingsGroup,
	SettingsItemCheckbox,
	SettingsItemTextField,
} from '@elipssolution/harfang';
import { mdiPencil, mdiPlus } from '@mdi/js';
import { styled } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import SettingsDialogPage from '../../../../../../components/SettingsDialogPage';
import { useSettingsDialog } from '../../../../../../hooks/useSettingsDialog';
import { CustomerFileType } from '../../../../../../types/customerFile';
import { generateErrorInformations } from '../../../../../../utils/errorHandler';
import {
	CREATE_SETTING_CUSTOMER_FILE_CHECK_ACCOUNT_TYPE,
	CreateSettingCustomerFileCheckAccountTypeType,
	REMOVE_SETTING_CUSTOMER_FILE_CHECK_ACCOUNT_TYPE,
	RemoveSettingCustomerFileCheckAccountTypeType,
	UPDATE_SETTING_CUSTOMER_FILE_CHECK_ACCOUNT_TYPE,
	UpdateSettingCustomerFileCheckAccountTypeType,
} from '../../../../api/settingCheckAccountType';
import { SettingCustomerFileCheckAccountType } from '../../../../types/settingCheckAccountType';

const ActionWrapper = styled('div')({
	display: 'flex',
	flexDirection: 'row-reverse',
	justifyContent: 'space-between',
});

const ErrorWrapper = styled('div')(({ theme: { palette, shape } }) => ({
	height: 36,
	width: '50%',

	display: 'grid',
	placeItems: 'center',

	color: palette.error.main,
	backgroundColor: `${palette.error.main}1A`,
	borderRadius: shape.borderRadius * 2,
}));

type CheckAccountTypesCustomerFileFormProps = {
	customerFileId?: CustomerFileType['id'];
	selectedCheckAccountType?: SettingCustomerFileCheckAccountType;
};

type FormFieldType = Pick<SettingCustomerFileCheckAccountType, 'accountPrefix' | 'isEnabled' | 'name'>;

const defaultConfirmationDialogDetails = {
	dialogErrorMessage: undefined,
	isOpen: false,
};

const CheckAccountTypesCustomerFileForm = ({
	selectedCheckAccountType: initialCheckAccountType,
	customerFileId,
}: CheckAccountTypesCustomerFileFormProps) => {
	const { back } = useSettingsDialog();

	const {
		control,
		formState: { isDirty, isValid },
		handleSubmit,
		reset,
	} = useForm<FormFieldType>();

	const [checkAccountType, setCheckAccountType] = useState(initialCheckAccountType);
	const [errorMessage, setErrorMessage] = useState<string>();
	const [{ dialogErrorMessage, isOpen }, setConfirmationDialogDetails] = useState<{
		dialogErrorMessage?: string;
		isOpen: boolean;
	}>(defaultConfirmationDialogDetails);

	const {
		id: checkAccountTypeId,
		name: checkAccountTypeName,
		isInherited,
		isOverload,
	} = useMemo(() => (checkAccountType || {}) as SettingCustomerFileCheckAccountType, [checkAccountType]);

	const mutationButtonName = useMemo(() => {
		if (isInherited && checkAccountTypeId) return 'Surcharger';

		return !checkAccountTypeId ? 'Ajouter' : 'Modifier';
	}, [isInherited, checkAccountTypeId]);

	const isDomainRelated = useMemo(() => isInherited || isOverload, [isInherited, isOverload]);

	const removeButtonName = useMemo(() => (isDomainRelated ? 'Revenir au domaine' : 'Supprimer'), [isDomainRelated]);

	const confirmationDialogTitle = useMemo(
		() =>
			isDomainRelated
				? 'Êtes-vous sûr de vouloir revenir au type de compte saisie chèques domaine ?'
				: `Êtes-vous sûr de vouloir supprimer le type de compte saisie chèques ${checkAccountTypeName} ?`,
		[checkAccountTypeName, isDomainRelated],
	);

	const mutationButtonstartIcon = useMemo(
		() => <Icon path={checkAccountTypeId ? mdiPencil : mdiPlus} />,
		[checkAccountTypeId],
	);

	const closeConfirmationDialog = useCallback(() => setConfirmationDialogDetails(defaultConfirmationDialogDetails), []);

	const openConfirmationDialog = useCallback(
		() =>
			setConfirmationDialogDetails({
				isOpen: true,
			}),
		[],
	);

	const [createSettingCustomerFileCheckAccountType, { loading: isCreateLoading }] =
		useMutation<CreateSettingCustomerFileCheckAccountTypeType>(CREATE_SETTING_CUSTOMER_FILE_CHECK_ACCOUNT_TYPE);

	const [updateSettingCustomerFileCheckAccountType, { loading: isUpdateLoading }] =
		useMutation<UpdateSettingCustomerFileCheckAccountTypeType>(UPDATE_SETTING_CUSTOMER_FILE_CHECK_ACCOUNT_TYPE);

	const [removeSettingCustomerFileCheckAccountType, { loading: isRemoveLoading }] =
		useMutation<RemoveSettingCustomerFileCheckAccountTypeType>(REMOVE_SETTING_CUSTOMER_FILE_CHECK_ACCOUNT_TYPE, {
			onCompleted: back,
			onError: (error: ApolloError) =>
				setConfirmationDialogDetails((prevValue) => ({
					...prevValue,
					dialogErrorMessage: generateErrorInformations({
						error,
						resource: 'quickentry_removeSettingCustomerFileCheckAccountType',
					})?.message,
				})),
		});

	const isLoading = useMemo(() => isCreateLoading || isUpdateLoading, [isCreateLoading, isUpdateLoading]);

	const isMutationButtonDisabled = useMemo(
		() => isRemoveLoading || !isValid || !isDirty,
		[isDirty, isRemoveLoading, isValid],
	);

	const onSubmit = useCallback(
		(values: FormFieldType) => {
			const { accountPrefix, isEnabled, name } = values;

			setErrorMessage('');

			const modeMutationInfoMap = new Map([
				[
					'create',
					{
						mutation: createSettingCustomerFileCheckAccountType,
						mutationName: 'quickentry_createSettingCustomerFileCheckAccountType',
						inputValues: {
							...(isInherited && { overriddenCheckAccountTypeId: checkAccountTypeId }),
							accountPrefix,
							customerFileId,
							isEnabled,
							name,
						},
					},
				],
				[
					'update',
					{
						mutation: updateSettingCustomerFileCheckAccountType,
						mutationName: 'quickentry_updateSettingCustomerFileCheckAccountType',
						inputValues: {
							id: checkAccountTypeId,
							isEnabled,
							name,
						},
					},
				],
			]);

			const {
				mutation,
				mutationName = '',
				inputValues,
			} = modeMutationInfoMap.get(!checkAccountTypeId || (isInherited && checkAccountTypeId) ? 'create' : 'update') ??
			{};

			return mutation?.({
				variables: {
					[`${mutationName}Input`]: inputValues,
				},
			})
				.then(({ data }) => {
					if (!data) return;

					const { id }: { id: string } = data[`${mutationName}` as keyof typeof data];

					setCheckAccountType({
						...values,
						isInherited: false,
						isOverload: isInherited ?? false,
						id,
					});
				})
				.catch((error: ApolloError) =>
					setErrorMessage(
						generateErrorInformations({
							error,
							resource:
								!checkAccountTypeId || (isInherited && checkAccountTypeId)
									? 'createSettingCustomerFileCheckAccountType'
									: 'updateSettingCustomerFileCheckAccountType',
						})?.message,
					),
				);
		},
		[
			createSettingCustomerFileCheckAccountType,
			updateSettingCustomerFileCheckAccountType,
			customerFileId,
			checkAccountTypeId,
			isInherited,
		],
	);

	const handleRemoval = useCallback(
		() =>
			removeSettingCustomerFileCheckAccountType({
				variables: { quickentryRemoveSettingCustomerFileCheckAccountTypeId: checkAccountTypeId },
			}),
		[removeSettingCustomerFileCheckAccountType, checkAccountTypeId],
	);
	const actionsDialog = useMemo(
		(): DialogProps['actionsDialog'] => [
			{
				disabled: isRemoveLoading,
				label: 'Annuler',
				onClick: closeConfirmationDialog,
			},
			{
				loading: isRemoveLoading,
				persistantErrorMessage: dialogErrorMessage,
				color: 'error',
				label: removeButtonName,
				onClick: handleRemoval,
				variant: 'contained',
			},
		],
		[isRemoveLoading, closeConfirmationDialog, dialogErrorMessage, removeButtonName, handleRemoval],
	);

	useEffect(() => reset(checkAccountType), [reset, checkAccountType]);

	return (
		<SettingsDialogPage title={checkAccountTypeName ?? "Ajout d'un type de compte saisie chèques"}>
			<SettingsGroup>
				<Controller
					control={control}
					name="name"
					render={({ field }) => (
						<SettingsItemTextField
							{...field}
							description="Nom du type de compte saisie chèques."
							label="Nom"
							required
						/>
					)}
					rules={{ required: true }}
				/>
				<Controller
					control={control}
					name="accountPrefix"
					render={({ field }) => (
						<SettingsItemTextField
							{...field}
							description="Préfixe du type de compte saisie chèques."
							disabled={!!checkAccountTypeId}
							label="Préfixe de compte"
							required
						/>
					)}
					rules={{ required: true }}
				/>
				<Controller
					control={control}
					defaultValue
					name="isEnabled"
					render={({ field: { value, ...field } }) => (
						<SettingsItemCheckbox
							{...field}
							checked={value}
							description="Si coché, le type du compte saisie de chèques est actif."
							label="Actif"
						/>
					)}
				/>
			</SettingsGroup>

			<ActionWrapper>
				<Button
					disabled={isMutationButtonDisabled}
					onClick={handleSubmit(onSubmit)}
					startIcon={mutationButtonstartIcon}
					variant="contained"
				>
					{mutationButtonName}
				</Button>

				{errorMessage && <ErrorWrapper>{errorMessage}</ErrorWrapper>}

				{checkAccountTypeId && !isInherited && (
					<Button color="error" disabled={isLoading} onClick={openConfirmationDialog} variant="outlined">
						{removeButtonName}
					</Button>
				)}
			</ActionWrapper>

			{checkAccountTypeId && (
				<ConfirmationDialog
					actionsDialog={actionsDialog}
					open={isOpen}
					onClose={closeConfirmationDialog}
					title={confirmationDialogTitle}
				/>
			)}
		</SettingsDialogPage>
	);
};

export default CheckAccountTypesCustomerFileForm;
