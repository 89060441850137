import { ApolloError, useMutation, useQuery } from '@apollo/client';
import {
	Button,
	Chip,
	ConfirmationDialog,
	DialogProps,
	SettingsGroup,
	SettingsItemCheckbox,
	SettingsItemTextField,
	Tooltip,
} from '@elipssolution/harfang';
import { mdiPencil, mdiPlus } from '@mdi/js';
import Icon from '@mdi/react';
import { Typography, styled } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import SettingsDialogPage from '../../../../../components/SettingsDialogPage';
import { useSettingsDialog } from '../../../../../hooks/useSettingsDialog';
import { generateErrorInformations } from '../../../../../utils/errorHandler';
import {
	CREATE_STORAGE_UPLOAD,
	CreateStorageUploadType,
	FETCH_STORAGE_UPLOAD,
	FetchStorageUploadType,
	REMOVE_STORAGE_UPLOAD,
	UPDATE_STORAGE_UPLOAD,
	UpdateStorageUploadType,
} from '../../../api/settingStorageUpload';
import ConnectorForm, { ConnectorFormType } from '../../../components/ConnectorForm';
import SettingsItemDragTextField from '../../../components/SettingsItemDragTextField';
import { ConnectorType } from '../../../types/connector';
import { SettingStorageUploadType } from '../../../types/settingStorage';

const TagsWrapper = styled('div')(({ theme }) => ({
	display: 'flex',
	flexWrap: 'wrap',
	gap: theme.spacing(1),
}));

const ActionsWrapper = styled('div')({
	display: 'flex',
	flexDirection: 'row-reverse',
	justifyContent: 'space-between',
});

const ErrorWrapper = styled('div')(({ theme }) => ({
	height: 36,
	width: '50%',

	display: 'grid',
	placeItems: 'center',

	color: theme.palette.error.main,
	backgroundColor: `${theme.palette.error.main}1A`,
	borderRadius: theme.shape.borderRadius * 2,
}));

const documentPathChips = [
	{
		color: 'error',
		id: 'customerFile',
		label: 'Dossier client',
	},
];

type StorageUploadFormProps = {
	storageUpload?: SettingStorageUploadType;
};
type StorageUploadFormType = {
	isImmediatelyProcessed: boolean;
	name: string;
	documentPath: string;
};

const StorageUploadForm = ({ storageUpload: initialStorageUpload }: StorageUploadFormProps) => {
	const { name: initialStorageUploadName } = initialStorageUpload ?? {};
	const { back } = useSettingsDialog();

	const {
		control,
		formState: { isDirty, isValid },
		handleSubmit,
		reset,
	} = useForm<StorageUploadFormType>();

	const [title, setTitle] = useState(initialStorageUploadName);
	const [storageUpload, setStorageUpload] = useState(initialStorageUpload);
	const [connector, setConnector] = useState<ConnectorType>();
	const [error, setError] = useState<string>();
	const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);
	const [confirmationDialogError, setConfirmationDialogError] = useState<string>();
	const [connectorFormValues, setConnectorFormValues] = useState<ConnectorFormType>();
	const [isConfigurationValid, setIsConfigurationValid] = useState(false);

	const openConfirmationDialog = useCallback(() => setIsConfirmationDialogOpen(true), []);
	const closeConfirmationDialog = useCallback(() => {
		setIsConfirmationDialogOpen(false);
		setConfirmationDialogError(undefined);
	}, []);

	const applyStorageUploadToForm = useCallback(
		({ documentPath, isImmediatelyProcessed, name }: SettingStorageUploadType) => {
			reset({
				documentPath,
				isImmediatelyProcessed,
				name,
			});
		},
		[reset],
	);

	const { data: storageUploadData, loading: isStorageUploadFetchLoading } = useQuery<FetchStorageUploadType>(
		FETCH_STORAGE_UPLOAD,
		{
			onCompleted: ({ document_settingStorageUpload }) => applyStorageUploadToForm(document_settingStorageUpload),
			onError: (fetchError) =>
				setError(generateErrorInformations({ error: fetchError, resource: 'document_settingStorageUpload' }).message),
			skip: !storageUpload,
			variables: { id: storageUpload?.id },
		},
	);

	const connectorInitialFormValues = useMemo(() => {
		if (storageUploadData?.document_settingStorageUpload?.connector)
			return {
				configuration: JSON.parse(storageUploadData?.document_settingStorageUpload.configuration ?? '{}') as Record<
					string,
					string
				>,
				connector: storageUploadData?.document_settingStorageUpload.connector,
			};
		return undefined;
	}, [storageUploadData]);

	const { tags } = useMemo(
		() => storageUploadData?.document_settingStorageUpload ?? { name: undefined, tags: [] },
		[storageUploadData],
	);

	const hasTags = useMemo(() => tags.length !== 0, [tags]);

	const [createStorageUpload, { loading: isCreateStorageUploadLoading }] = useMutation<CreateStorageUploadType>(
		CREATE_STORAGE_UPLOAD,
		{
			onCompleted: ({ document_createSettingStorageUpload }) => setStorageUpload(document_createSettingStorageUpload),
		},
	);

	const [updateStorageUpload, { loading: isUpdateStorageUploadLoading }] =
		useMutation<UpdateStorageUploadType>(UPDATE_STORAGE_UPLOAD);

	const [removeStorageUpload, { loading: isRemoveStorageUploadLoading }] = useMutation(REMOVE_STORAGE_UPLOAD, {
		onCompleted: back,
		onError: (mutationError) =>
			setConfirmationDialogError(
				generateErrorInformations({ error: mutationError, resource: 'document_removeSettingStorageUpload' }).message,
			),
	});

	const isLoading = useMemo(
		() =>
			isStorageUploadFetchLoading ||
			isCreateStorageUploadLoading ||
			isUpdateStorageUploadLoading ||
			isRemoveStorageUploadLoading,
		[
			isStorageUploadFetchLoading,
			isCreateStorageUploadLoading,
			isUpdateStorageUploadLoading,
			isRemoveStorageUploadLoading,
		],
	);

	const isMutationButtonDisabled = useMemo(
		() => isLoading || !isValid || !(isConfigurationValid || isDirty),
		[isConfigurationValid, isDirty, isLoading, isValid],
	);

	const handleRemoval = useCallback(
		() => removeStorageUpload({ variables: { id: storageUpload?.id } }),

		[removeStorageUpload, storageUpload],
	);

	const onSubmit = useCallback(
		(values: StorageUploadFormType) => {
			const { configuration, connector: connectorValue } = connectorFormValues ?? {};
			const storageUploadFormData = {
				...values,
				configuration: JSON.stringify(configuration ?? {}),
				connector: connectorValue,
			};

			const modeMutationInfoMap = new Map([
				[
					'create',
					{
						mutation: createStorageUpload,
						mutationName: 'createSettingStorageUpload',
						inputValues: storageUploadFormData,
					},
				],
				[
					'update',
					{
						mutation: updateStorageUpload,
						mutationName: 'updateSettingStorageUpload',
						inputValues: {
							...storageUploadFormData,
							id: storageUpload?.id,
						},
					},
				],
			]);

			const {
				mutation,
				mutationName = '',
				inputValues,
			} = modeMutationInfoMap.get(storageUpload?.id ? 'update' : 'create') || {};

			return mutation?.({
				variables: {
					[`${mutationName}Input`]: inputValues,
				},
			})
				.then(({ data }) => {
					if (!data) return;

					const { name } = values;

					setTitle(name);

					reset(values);

					setError(undefined);
				})
				.catch((mutationError: ApolloError) =>
					setError(
						generateErrorInformations({
							error: mutationError,
							resource: storageUpload?.id ? 'updateSettingStorageUpload' : 'createSettingStorageUpload',
						}).message,
					),
				);
		},
		[connectorFormValues, createStorageUpload, updateStorageUpload, storageUpload?.id, reset],
	);

	const startIcon = useMemo(() => <Icon path={storageUpload ? mdiPencil : mdiPlus} size="24px" />, [storageUpload]);
	const actionsDialog = useMemo(
		(): DialogProps['actionsDialog'] => [
			{
				disabled: isRemoveStorageUploadLoading,
				label: 'Annuler',
				onClick: closeConfirmationDialog,
			},
			{
				loading: isRemoveStorageUploadLoading,
				persistantErrorMessage: confirmationDialogError,
				color: 'error',
				label: 'Supprimer',
				onClick: handleRemoval,
				variant: 'contained',
			},
		],
		[closeConfirmationDialog, handleRemoval, isRemoveStorageUploadLoading, confirmationDialogError],
	);

	return (
		<SettingsDialogPage title={title ?? "Ajout d'un emplacement de dépôt"}>
			<SettingsGroup>
				<Controller
					control={control}
					render={({ field }) => (
						<SettingsItemTextField
							{...field}
							description="Nom de l'emplacement de dépôt."
							disabled={isStorageUploadFetchLoading}
							label="Nom"
							required
						/>
					)}
					rules={{ required: true }}
					name="name"
				/>
				<Controller
					control={control}
					defaultValue={false}
					render={({ field: { value, ...field } }) => (
						<SettingsItemCheckbox
							{...field}
							checked={value}
							description="Si coché, le document dépôsé est immédiatement traité."
							disabled={isStorageUploadFetchLoading}
							label="Traitement immédiat"
						/>
					)}
					name="isImmediatelyProcessed"
				/>
			</SettingsGroup>

			<ConnectorForm
				values={connectorFormValues}
				onValuesChange={setConnectorFormValues}
				onConnectorChange={setConnector}
				connector={connector}
				onConfigurationValidityChange={setIsConfigurationValid}
				initialValues={connectorInitialFormValues}
				type="upload"
				hasStorageData={!!storageUploadData && storageUploadData.document_settingStorageUpload !== null}
			/>

			<SettingsGroup label="Arborescence" actionsWidth={550}>
				<Controller
					control={control}
					render={({ field }) => (
						<SettingsItemDragTextField
							{...field}
							chips={documentPathChips}
							description="Emplacement du dépôt."
							label="Chemin du document"
						/>
					)}
					name="documentPath"
				/>
			</SettingsGroup>

			{tags.length > 0 && (
				<div>
					<Typography paddingLeft={1} variant="h6">
						Tags associés
					</Typography>
					<TagsWrapper>
						{tags.map(({ color, id, name: tagName }) => (
							<Chip key={id} color={color} label={tagName} />
						))}
					</TagsWrapper>
				</div>
			)}

			<ActionsWrapper>
				<Button
					disabled={isMutationButtonDisabled}
					onClick={handleSubmit(onSubmit)}
					startIcon={startIcon}
					variant="contained"
				>
					{storageUpload ? 'Modifier' : 'Ajouter'}
				</Button>

				{error && <ErrorWrapper>{error}</ErrorWrapper>}

				{storageUpload && (
					<Tooltip content={hasTags ? 'Un emplacement avec des tags associés ne peut pas être supprimé' : ''}>
						<div>
							<Button color="error" disabled={isLoading || hasTags} onClick={openConfirmationDialog} variant="outlined">
								Supprimer
							</Button>
						</div>
					</Tooltip>
				)}
			</ActionsWrapper>

			{storageUpload && (
				<ConfirmationDialog
					actionsDialog={actionsDialog}
					open={isConfirmationDialogOpen}
					onClose={closeConfirmationDialog}
					title="Êtes-vous sûr de vouloir supprimer ce dépôt ?"
				/>
			)}
		</SettingsDialogPage>
	);
};

export default StorageUploadForm;
