import { ApolloError, useLazyQuery, useMutation } from '@apollo/client';
import { Chip, ConfirmationDialog, DialogProps, IconButton, TableColumnType, Tooltip } from '@elipssolution/harfang';
import { mdiDelete, mdiInformationOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { useCallback, useMemo, useState } from 'react';

import AddCustomerFileUserRelationForm from './AddCustomerFileUserRelationForm';
import SettingsDialogPage from '../../../../components/SettingsDialogPage';
import SettingsTable from '../../../../components/SettingsTable';
import { useSettingsDialog } from '../../../../hooks/useSettingsDialog';
import { CustomerFileType } from '../../../../types/customerFile';
import { generateErrorInformations } from '../../../../utils/errorHandler';
import {
	FETCH_INTERNAL_USER_CUSTOMER_FILE_RELATIONS_BY_CUSTOMER_FILE,
	FetchInternalUserCustomerFileRelationsByCustomerFileType,
	REMOVE_INTERNAL_USER_CUSTOMER_FILE_RELATION,
} from '../../../api/internalUserCustomerFile';
import { InternalUserCustomerFileRelationType } from '../../../types/relation';

const defaultConfirmationDialogDetails = {
	dialogErrorMessage: undefined,
	isOpen: false,
	relationId: '',
};

type CustomerFileProps = {
	customerFile: CustomerFileType;
};

const InternalCustomerFileUserRelation = ({ customerFile }: CustomerFileProps) => {
	const { push } = useSettingsDialog();

	const { id: customerFileId, name: customerFileName } = customerFile;

	const [{ dialogErrorMessage, isOpen, relationId }, setConfirmationDialogDetails] = useState<{
		dialogErrorMessage?: string;
		isOpen: boolean;
		relationId: string;
	}>(defaultConfirmationDialogDetails);

	const closeConfirmationDialog = useCallback(() => setConfirmationDialogDetails(defaultConfirmationDialogDetails), []);

	const openConfirmationDialog = useCallback(
		(relationIdSelected: string) =>
			setConfirmationDialogDetails({
				isOpen: true,
				relationId: relationIdSelected,
			}),
		[],
	);

	const columns: TableColumnType<InternalUserCustomerFileRelationType>[] = useMemo(
		() => [
			{
				key: 'internalUser',
				render: ({ user: { lastName, firstName } }) => `${firstName ?? ''} ${lastName}`.trim(),
				title: "Nom de l'utilisateur",
				width: 200,
			},
			{
				key: 'email',
				render: ({ user: { email } }) => email,
				title: 'Email',
				width: 250,
			},
			{
				flexGrow: 0,
				key: 'hasUserPermissions',
				render: ({ user: { hasUserPermissions } }) =>
					hasUserPermissions && (
						<Tooltip content="Les droits de l'utilisateur ont été personnalisés">
							<Icon path={mdiInformationOutline} />
						</Tooltip>
					),
				width: 24,
			},
			{
				flexGrow: 0,
				key: 'group',
				render: ({
					user: {
						group: { isAdministrator, isDefault, name },
					},
				}) => {
					if (isAdministrator) return <Chip label={name} color="error" />;

					return <Chip label={name} color={isDefault ? 'info' : 'default'} />;
				},
				width: 120,
			},
			{
				align: 'center',
				key: 'actions',
				flexGrow: 0,
				render: ({
					id,
					user: {
						group: { isAdministrator },
					},
				}) =>
					!isAdministrator && (
						<IconButton onClick={() => openConfirmationDialog(id)}>
							<Icon path={mdiDelete} />
						</IconButton>
					),
				width: 40,
			},
		],
		[openConfirmationDialog],
	);

	const [
		removeInternalUserCustomerFileRelation,
		{
			loading: isRemoveInternalUserCustomerFileRelationLoading,
			called: isRemoveInternalUserCustomerFileRelationCalled,
			reset: resetRemoveInternalUserCustomerFileRelation,
		},
	] = useMutation(REMOVE_INTERNAL_USER_CUSTOMER_FILE_RELATION, {
		onCompleted: closeConfirmationDialog,
		onError: (error: ApolloError) =>
			setConfirmationDialogDetails((prevValue) => ({
				...prevValue,
				dialogErrorMessage: generateErrorInformations({ error, resource: 'removeInternalUserCustomerFileRelation' })
					?.message,
			})),
	});

	const [fetchInternalUserCustomerFileByCustomerFile] =
		useLazyQuery<FetchInternalUserCustomerFileRelationsByCustomerFileType>(
			FETCH_INTERNAL_USER_CUSTOMER_FILE_RELATIONS_BY_CUSTOMER_FILE,
		);

	const internalUserCustomerFileByCustomerFileDataSource = useCallback(
		async (
			limit: number,
			offset: number,
			search?: string,
		): Promise<{
			count: number;
			items: InternalUserCustomerFileRelationType[];
		}> => {
			const { data, error } = await fetchInternalUserCustomerFileByCustomerFile({
				variables: {
					customerFileId,
					page: {
						limit,
						offset,
					},
					search,
				},
			});

			if (error) {
				throw error;
			}

			const {
				internalUserCustomerFileRelationsByCustomerFile: { count = 0, items = [] },
			} = data ?? {
				internalUserCustomerFileRelationsByCustomerFile: {},
			};

			isRemoveInternalUserCustomerFileRelationCalled && resetRemoveInternalUserCustomerFileRelation();

			return { count, items };
		},
		[
			fetchInternalUserCustomerFileByCustomerFile,
			customerFileId,
			isRemoveInternalUserCustomerFileRelationCalled,
			resetRemoveInternalUserCustomerFileRelation,
		],
	);

	const deleteInternalUserCustomerFileRelation = useCallback(
		(internalUserCustomerFileRelationId: string) =>
			removeInternalUserCustomerFileRelation({
				variables: {
					removeInternalUserCustomerFileRelationId: internalUserCustomerFileRelationId,
				},
			}),
		[removeInternalUserCustomerFileRelation],
	);

	const handleDeleteInternalUserCustomerFileRelation = useCallback(
		() => deleteInternalUserCustomerFileRelation(relationId),
		[deleteInternalUserCustomerFileRelation, relationId],
	);

	const handleAddButtonClick = useCallback(
		() => push(<AddCustomerFileUserRelationForm customerFile={customerFile} />),
		[customerFile, push],
	);

	const actionsDialog = useMemo(
		(): DialogProps['actionsDialog'] => [
			{
				disabled: isRemoveInternalUserCustomerFileRelationLoading,
				label: 'Annuler',
				onClick: closeConfirmationDialog,
			},
			{
				loading: isRemoveInternalUserCustomerFileRelationLoading,
				persistantErrorMessage: dialogErrorMessage,
				onClick: handleDeleteInternalUserCustomerFileRelation,
				color: 'error',
				label: 'Supprimer',
				variant: 'contained',
			},
		],
		[
			closeConfirmationDialog,
			handleDeleteInternalUserCustomerFileRelation,
			dialogErrorMessage,
			isRemoveInternalUserCustomerFileRelationLoading,
		],
	);

	return (
		<SettingsDialogPage title={customerFileName}>
			<SettingsTable<InternalUserCustomerFileRelationType>
				addButtonLabel="Ajout d'utilisateurs"
				dataSource={internalUserCustomerFileByCustomerFileDataSource}
				onAddButtonClick={handleAddButtonClick}
				tableColumns={columns}
				title="Utilisateurs"
			/>

			<ConfirmationDialog
				actionsDialog={actionsDialog}
				maxWidth={false}
				onClose={closeConfirmationDialog}
				open={isOpen}
				title="Êtes-vous sûr de vouloir supprimer la relation utilisateur / dossier client ?"
			/>
		</SettingsDialogPage>
	);
};

export default InternalCustomerFileUserRelation;
