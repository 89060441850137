import { ButtonSwitch, DateRangePicker } from '@elipssolution/harfang';
import { Divider, FormControlLabel, FormGroup, Stack, Switch } from '@mui/material';
import { ChangeEvent, useCallback } from 'react';
import { FiscalYearType } from '../../../../types/fiscalYear';
import FiscalYearAutocomplete from '../../components/FiscalYearAutocomplete';
import { DecimalFilter, SwitchOperatorType } from '../../types/table';
import { ThirdPartyTypeEnum } from '../../types/thirdParty';

const filters: FilterType[] = [
	{
		id: 'ne',
		label: 'Solde différent de 0',
	},
	{
		id: 'lt',
		label: 'Solde négatif',
	},
	{
		id: 'gt',
		label: 'Solde positif',
	},
];

const types = [
	{ id: ThirdPartyTypeEnum.SUPPLIER, label: 'Fournisseur' },
	{ id: ThirdPartyTypeEnum.CUSTOMER, label: 'Client' },
];

type FilterType = {
	id: SwitchOperatorType;
	label: string;
};

type ThirdPartiesTableTogglesType = {
	selectedFiscalYear?: FiscalYearType;
	onFiscalYearSelection: (fiscalYear?: FiscalYearType) => void;
	selectedDateRange?: [Date, Date];
	onDateRangeSelection: (dateRange?: [Date, Date]) => void;
	selectedType: ThirdPartyTypeEnum;
	onTypeSelection: (typeId: ThirdPartyTypeEnum) => void;
	balanceFilter?: DecimalFilter;
	onBalanceFilterOperatorChange: (operator: FilterType['id']) => void;
	hasTransaction: boolean;
	onHasTransactionChange: (bool: boolean) => void;
};

const ThirdPartiesTableToggles = ({
	selectedFiscalYear,
	onFiscalYearSelection,
	selectedDateRange,
	onDateRangeSelection,
	selectedType,
	onTypeSelection,
	balanceFilter,
	hasTransaction,
	onBalanceFilterOperatorChange,
	onHasTransactionChange,
}: ThirdPartiesTableTogglesType) => {
	const { startDate, endDate } = selectedFiscalYear || {};

	const handleTypeSelection = useCallback(
		(id: string) => onTypeSelection(types.find(({ id: typeId }) => id === typeId)?.id as ThirdPartyTypeEnum),
		[onTypeSelection],
	);
	const handleBalanceFilterSwitchChange = ({ target: { id: targetId } }: ChangeEvent<HTMLInputElement>) =>
		onBalanceFilterOperatorChange(targetId as FilterType['id']);

	const handleHasTransactionChange = useCallback(
		() => onHasTransactionChange(!hasTransaction),
		[hasTransaction, onHasTransactionChange],
	);

	const handleFiscalYearChange = useCallback(
		(fiscalYear?: FiscalYearType) => {
			onFiscalYearSelection(fiscalYear);
			onDateRangeSelection(undefined);
		},
		[onDateRangeSelection, onFiscalYearSelection],
	);

	return (
		<Stack height="100%" gap={2} flex={1}>
			<ButtonSwitch items={types} onClick={handleTypeSelection} selectedItem={selectedType} />

			<Divider />

			<FiscalYearAutocomplete selectedFiscalYear={selectedFiscalYear} onFiscalYearSelection={handleFiscalYearChange} />
			<DateRangePicker
				label="Plage de dates"
				disabled={!selectedFiscalYear}
				min={startDate}
				max={endDate}
				value={selectedDateRange}
				onChange={onDateRangeSelection}
			/>

			<Divider />

			<FormGroup>
				{filters.map(({ id, label }) => (
					<FormControlLabel
						key={id}
						control={
							<Switch id={id} checked={balanceFilter?.[id] === '0.00'} onChange={handleBalanceFilterSwitchChange} />
						}
						label={label}
					/>
				))}
				<FormControlLabel
					control={<Switch checked={hasTransaction} onChange={handleHasTransactionChange} />}
					label="Comptes mouvementés"
				/>
			</FormGroup>
		</Stack>
	);
};

export default ThirdPartiesTableToggles;
