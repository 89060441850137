import { ApolloError, useLazyQuery, useMutation } from '@apollo/client';
import {
	Button,
	ConfirmationDialog,
	DialogProps,
	Icon,
	SettingsGroup,
	SettingsItemAutocomplete,
	SettingsItemCheckbox,
	SettingsItemColor,
	SettingsItemTextField,
} from '@elipssolution/harfang';
import { mdiAlertCircle, mdiCheck, mdiPencil, mdiPlus } from '@mdi/js';
import { Typography, styled } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import SettingCustomerFileTagNameField from './SettingCustomerFileTagNameField';
import SettingsDialogPage from '../../../../../../components/SettingsDialogPage';
import { useSettingsDialog } from '../../../../../../hooks/useSettingsDialog';
import { CustomerFileWithDomainType } from '../../../../../../types/customerFile';
import { generateErrorInformations } from '../../../../../../utils/errorHandler';
import {
	CREATE_SETTING_CUSTOMER_FILE_TAG,
	CreateSettingCustomerFileType,
	FETCH_SETTING_CUSTOMER_FILE_TAG,
	FetchSettingCustomerFileTagType,
	REMOVE_SETTING_CUSTOMER_FILE_TAG,
	UPDATE_SETTING_CUSTOMER_FILE_TAG,
	UpdateSettingCustomerFileTagType,
} from '../../../../api/settingCustomerFileTag';
import { FETCH_STORAGE_UPLOADS, FetchStorageUploadsType } from '../../../../api/settingStorageUpload';
import { SettingStorageUploadType } from '../../../../types/settingStorage';
import { SettingTagByCustomerFileType } from '../../../../types/settingTag';

const ErrorWrapper = styled('div')(({ theme }) => ({
	overflow: 'auto',

	minHeight: 100,
	minWidth: '50%',
	maxWidth: '75%',

	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',

	padding: theme.spacing(1),
	gap: theme.spacing(1),
	margin: '16px auto 0 auto',

	color: theme.palette.error.main,
	backgroundColor: `${theme.palette.error.main}1A`,
	borderRadius: theme.shape.borderRadius * 2,
}));

const ButtonWrapper = styled('div')({
	display: 'flex',
	flexDirection: 'row-reverse',
	justifyContent: 'space-between',
});

type CustomerFileTagsFormProps = {
	customerFile: CustomerFileWithDomainType;
	tag?: SettingTagByCustomerFileType;
};

type FormFieldType = Pick<SettingTagByCustomerFileType, 'color' | 'isEnabled' | 'name' | 'storageUpload' | 'info'>;

const defaultConfirmationDialogDetails = {
	dialogErrorMessage: undefined,
	isOpen: false,
};

const CustomerFileTagsForm = ({ customerFile, tag }: CustomerFileTagsFormProps) => {
	const { id: customerFileId } = customerFile;
	const { id: initialTagId, name: initialTagName, isInherited, isOverload } = tag ?? {};

	const { back } = useSettingsDialog();

	const {
		clearErrors,
		control,
		formState: { isDirty, isValid },
		handleSubmit,
		setError,
		reset,
	} = useForm<FormFieldType>();

	const [tagId, setTagId] = useState(initialTagId);
	const [tagName, setTagName] = useState(initialTagName);
	const [isTagOverload, setIsTagOverload] = useState(isOverload);
	const [isTagInherited, setIsTagInherited] = useState(isInherited);
	const [isTagArchived, setIsTagArchived] = useState(false);
	const [errorMessage, setErrorMessage] = useState<string>();
	const [{ dialogErrorMessage, isOpen }, setConfirmationDialogDetails] = useState<{
		dialogErrorMessage?: string;
		isOpen: boolean;
	}>(defaultConfirmationDialogDetails);

	const [fetchSettingTag, { loading: isSettingCustomerFileTagLoading }] = useLazyQuery<FetchSettingCustomerFileTagType>(
		FETCH_SETTING_CUSTOMER_FILE_TAG,
		{
			onCompleted: ({ document_settingCustomerFileTag: { name } }) => {
				setTagName(name);
			},
			onError: (fetchError) =>
				setErrorMessage(
					generateErrorInformations({ error: fetchError, resource: 'document_settingCustomerFileTag' }).message,
				),
		},
	);

	const [fetchSettingStorageUploads] = useLazyQuery<FetchStorageUploadsType>(FETCH_STORAGE_UPLOADS);

	const settingStorageUploadsDataSource = useCallback(
		async (
			limit: number,
			offset: number,
			search?: string,
		): Promise<{
			items: SettingStorageUploadType[];
			count: number;
		}> => {
			const { data, error } = await fetchSettingStorageUploads({
				variables: {
					page: {
						limit,
						offset,
					},
					search,
				},
			});

			if (error) {
				throw error;
			}

			const {
				document_settingStorageUploads: { count = 0, items = [] },
			} = data ?? {
				document_settingStorageUploads: {},
			};

			return {
				items,
				count,
			};
		},
		[fetchSettingStorageUploads],
	);

	const closeConfirmationDialog = useCallback(() => setConfirmationDialogDetails(defaultConfirmationDialogDetails), []);

	const openConfirmationDialog = useCallback(
		() =>
			setConfirmationDialogDetails({
				isOpen: true,
			}),
		[],
	);

	const [createTag, { loading: isCreateTagLoading }] = useMutation<CreateSettingCustomerFileType>(
		CREATE_SETTING_CUSTOMER_FILE_TAG,
		{
			onCompleted: ({ document_createSettingCustomerFileTag: { id } }) => {
				if (tagId) {
					setIsTagOverload(true);
					setIsTagInherited(false);
				}
				setTagId(id);
			},
		},
	);

	const [updateTag, { loading: isUpdateTagLoading }] = useMutation<UpdateSettingCustomerFileTagType>(
		UPDATE_SETTING_CUSTOMER_FILE_TAG,
	);

	const [removeTag, { loading: isRemoveTagLoading }] = useMutation(REMOVE_SETTING_CUSTOMER_FILE_TAG, {
		onCompleted: back,
		onError: (error: ApolloError) =>
			setConfirmationDialogDetails((prevValue) => ({
				...prevValue,
				dialogErrorMessage: generateErrorInformations({ error, resource: 'document_removeSettingCustomerFileTag' })
					?.message,
			})),
	});

	const applyArchivedTag = useCallback(
		(tagArchivedId: string) => {
			setIsTagArchived(true);

			fetchSettingTag({
				variables: {
					settingCustomerFileTagId: tagArchivedId,
				},
			}).catch((error) => {
				throw error;
			});
		},
		[fetchSettingTag],
	);

	const isLoading = useMemo(
		() => isSettingCustomerFileTagLoading || isCreateTagLoading || isUpdateTagLoading,
		[isSettingCustomerFileTagLoading, isCreateTagLoading, isUpdateTagLoading],
	);

	const isMutationButtonDisabled = useMemo(
		() => isRemoveTagLoading || !isValid || (!isDirty && !isTagArchived),
		[isDirty, isRemoveTagLoading, isTagArchived, isValid],
	);

	const mutationButtonName = useMemo(() => {
		if (isTagInherited && tagId) return 'Surcharger le tag';

		if (isTagArchived) return 'Confirmer';

		return !tagId ? 'Ajouter' : 'Modifier';
	}, [isTagArchived, isTagInherited, tagId]);

	const startIcon = useMemo(
		() => (isTagArchived ? <Icon path={mdiCheck} /> : <Icon path={tagId ? mdiPencil : mdiPlus} />),
		[isTagArchived, tagId],
	);

	const onSubmit = useCallback(
		(values: FormFieldType) => {
			const { color, isEnabled, name, storageUpload, info } = values;
			const { id: storageUploadId } = storageUpload || {};

			const modeMutationInfoMap = new Map([
				[
					'create',
					{
						mutation: createTag,
						mutationName: 'createSettingCustomerFileTag',
						inputValues: {
							color,
							customerFileId,
							isEnabled,
							name,
							storageUploadId,
							...(tagId && { overriddenTagId: tagId }),
							info,
						},
					},
				],
				[
					'update',
					{
						mutation: updateTag,
						mutationName: 'updateSettingCustomerFileTag',
						inputValues: {
							id: tagId,
							color,
							isEnabled,
							name,
							storageUploadId,
							...(isTagArchived && { isArchived: false }),
							info: info || null,
						},
					},
				],
			]);

			const {
				mutation,
				mutationName = '',
				inputValues,
			} = modeMutationInfoMap.get(!tagId || (isTagInherited && tagId) ? 'create' : 'update') ?? {};

			mutation?.({
				variables: {
					[`${mutationName}Input`]: inputValues,
				},
			})
				.then(({ data }) => {
					if (!data) return;

					setIsTagArchived(false);

					reset(values);

					setTagName(name);
				})
				.catch((mutationError: ApolloError) =>
					setErrorMessage(generateErrorInformations({ error: mutationError, resource: mutationName }).message),
				);
		},
		[createTag, reset, updateTag, customerFileId, isTagInherited, isTagArchived, tagId],
	);

	const deleteTag = useCallback(
		() =>
			removeTag({
				variables: {
					removeSettingCustomerFileTagId: tagId,
				},
			}),
		[removeTag, tagId],
	);
	const actionsDialog = useMemo(
		(): DialogProps['actionsDialog'] => [
			{
				disabled: isRemoveTagLoading,
				label: 'Annuler',
				onClick: closeConfirmationDialog,
			},
			{
				loading: isRemoveTagLoading,
				persistantErrorMessage: dialogErrorMessage,
				onClick: deleteTag,
				color: 'error',
				label: 'Supprimer',
				variant: 'contained',
			},
		],
		[closeConfirmationDialog, deleteTag, dialogErrorMessage, isRemoveTagLoading],
	);
	useEffect(() => reset(tag), [reset, tag]);

	return (
		<SettingsDialogPage title={tagName ?? "Ajout d'un tag"}>
			<SettingsGroup>
				<SettingCustomerFileTagNameField
					clearErrors={clearErrors}
					control={control}
					customerFileId={customerFile.id}
					initialValue={tagName}
					onApplyArchivedTag={applyArchivedTag}
					setError={setError}
					tagId={tagId}
				/>
				<Controller
					control={control}
					name="info"
					render={({ field: { onChange, ...field }, fieldState: { error } }) => (
						<SettingsItemTextField
							{...field}
							description="Informations du tag d'entreprise."
							helperText={error?.message ?? ' '}
							invalid={!!error}
							label="Information"
							onChange={onChange}
						/>
					)}
				/>

				<Controller
					name="color"
					control={control}
					render={({ field: { value, ...field } }) => (
						<SettingsItemColor
							{...field}
							label="Couleur"
							description="Couleur du tag."
							selectedColorCode={value}
							required
						/>
					)}
					rules={{ required: true }}
				/>
				<Controller
					name="storageUpload"
					control={control}
					render={({ field: { ...field } }) => (
						<SettingsItemAutocomplete<SettingStorageUploadType>
							{...field}
							dataSource={settingStorageUploadsDataSource}
							description="Emplacement attaché au tag d'entreprise."
							getOptionLabel={(option) => option?.name || ''}
							label="Emplacement"
							required
						/>
					)}
					rules={{ required: true }}
				/>
				<Controller
					defaultValue
					name="isEnabled"
					control={control}
					render={({ field: { onChange, value, ...field } }) => (
						<SettingsItemCheckbox
							{...field}
							checked={value}
							description="Si coché, le tag d'entreprise est actif."
							disabled={isLoading}
							label="Actif"
							onChange={(_, checked: boolean) => onChange(checked)}
						/>
					)}
				/>
			</SettingsGroup>

			<ButtonWrapper>
				<Button
					disabled={isMutationButtonDisabled}
					onClick={handleSubmit(onSubmit)}
					startIcon={startIcon}
					variant="contained"
				>
					{mutationButtonName}
				</Button>

				{tagId && !isTagInherited && !isTagArchived && (
					<Button color="error" disabled={isLoading} onClick={openConfirmationDialog} variant="outlined">
						{!isTagInherited && isTagOverload ? 'Revenir au tag domaine' : 'Supprimer'}
					</Button>
				)}
			</ButtonWrapper>

			{errorMessage && (
				<ErrorWrapper>
					<Icon path={mdiAlertCircle} />
					<Typography>{errorMessage}</Typography>
				</ErrorWrapper>
			)}

			<ConfirmationDialog
				actionsDialog={actionsDialog}
				maxWidth={false}
				onClose={closeConfirmationDialog}
				open={isOpen}
				title={`Êtes-vous sûr de vouloir supprimer le tag ${tagName || ''} ?`}
			/>
		</SettingsDialogPage>
	);
};

export default CustomerFileTagsForm;
