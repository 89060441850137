import { useLazyQuery } from '@apollo/client';
import { TableFilterType, TableOrderByType } from '@elipssolution/harfang';
import { useCallback } from 'react';

import DataSourceResultFormatType from '../../../../../types/dataSource';
import { FetchSynchronizedVouchersType, FETCH_SYNCHRONIZED_VOUCHERS } from '../../../api/voucher';
import { CustomerFileTemplateType } from '../../../types/template';
import { VoucherFilterType, VoucherType } from '../../../types/voucher';

const useSynchronizedVouchersDataSource = ({
	templateFilterValue,
}: {
	templateFilterValue?: { eq: CustomerFileTemplateType };
}) => {
	const [fetchSynchronizedVouchers, { networkStatus }] = useLazyQuery<FetchSynchronizedVouchersType>(
		FETCH_SYNCHRONIZED_VOUCHERS,
		{
			notifyOnNetworkStatusChange: true,
		},
	);

	const dataSource = useCallback(
		async (
			limit: number,
			offset: number,
			search?: string,
			orderBy?: TableOrderByType<VoucherType>,
			filter?: TableFilterType<VoucherFilterType>,
		): Promise<DataSourceResultFormatType<VoucherType>> => {
			const { field, order } = orderBy || {};

			const {
				amount: filteredAmount,
				template: filteredTemplate,
				createdBy: createdByFilter,
				...restFilters
			} = filter ?? {};

			const { data, error } = await fetchSynchronizedVouchers({
				variables: {
					filter: {
						...restFilters,
						...(createdByFilter && {
							createdBy: {
								eq: createdByFilter.eq?.email,
							},
						}),
						...(filteredAmount && {
							amount: Object.entries(filteredAmount).reduce(
								(_, [key, value]) => ({
									[key]: value?.toString(),
								}),
								{},
							),
						}),
						...((filteredTemplate || templateFilterValue) && {
							templateId: {
								eq: (filteredTemplate ?? templateFilterValue)?.eq?.id,
							},
						}),
					},
					...(orderBy && { orderBy: { field, order } }),
					page: {
						limit,
						offset,
					},
					search,
				},
			});

			if (error) {
				throw error;
			}

			const {
				quickentry_synchronizedVouchers: { count = 0, items = [] },
			} = data ?? {
				quickentry_synchronizedVouchers: {},
			};

			return {
				count,
				items: items.map(({ accountingDate, ...rest }) => ({
					...rest,
					accountingDate: new Date(accountingDate),
				})),
			};
		},
		[fetchSynchronizedVouchers, templateFilterValue],
	);

	return { dataSource, networkStatus };
};

export default useSynchronizedVouchersDataSource;
