import { useQuery } from '@apollo/client';
import { Button, Icon, IconButton } from '@elipssolution/harfang';
import { mdiClose, mdiDownload } from '@mdi/js';
import { Dialog, DialogProps, Stack, Typography, styled } from '@mui/material';
import { ReactElement, useCallback } from 'react';

import { downloadFile } from '../../../../src/utils/file';
import { FETCH_DOCUMENT, FetchDocumentType } from '../../api/document';
import DocumentWrapper from '../../components/DocumentWrapper';

type InfoLineProps = {
	label: string;
	value: ReactElement;
};

const InfoLine = ({ label, value }: InfoLineProps) => (
	<Stack direction="column" gap={0.25}>
		<Typography fontWeight={800}>{label}:</Typography>
		<div>{value}</div>
	</Stack>
);

const StyledDialog = styled(({ className, ...props }: DialogProps) => (
	<Dialog {...props} classes={{ paper: className }} />
))(({ theme: { shape } }) => ({
	height: '90%',
	minWidth: '90%',
	flexDirection: 'row',
	borderRadius: shape.borderRadius * 3,
	overflow: 'hidden',
}));

const PreviewSection = styled('div')(({ theme: { palette, spacing } }) => ({
	width: `calc(80% - ${spacing(4)})`,
	backgroundColor: palette.grey[500],
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
}));

const InfoSection = styled('div')(({ theme: { spacing } }) => ({
	width: '20%',
	padding: spacing(2),
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'space-between',

	'& > div:nth-of-type(1)': {
		display: 'flex',
		flexDirection: 'column',
		gap: spacing(2),
	},

	'& > div:nth-of-type(2)': {
		display: 'flex',
		justifyContent: 'flex-end',
	},
}));

const InfoSectionHeader = styled('div')(({ theme: { spacing } }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	gap: spacing(1),
	marginBottom: spacing(2),
}));

type DocumentPreviewDialogProps = {
	document?: Blob;
	documentId?: string;
	onClose: () => void;
};

const DocumentPreviewDialog = ({ document, documentId, onClose }: DocumentPreviewDialogProps) => {
	const { data: documentData } = useQuery<FetchDocumentType>(FETCH_DOCUMENT, {
		skip: !documentId,
		variables: { documentId },
	});

	const { name, fileType, fiscalYear } = documentData?.document_document || {};
	const { name: fiscalYearName } = fiscalYear || {};

	const downloadDocument = useCallback(() => {
		if (document && name) {
			const documentObjectURL = URL.createObjectURL(document);

			downloadFile(documentObjectURL, name);

			URL.revokeObjectURL(documentObjectURL);
		}
	}, [name, document]);

	return (
		<StyledDialog open={!!document} onClose={onClose}>
			<PreviewSection>
				<DocumentWrapper fileType={fileType} fileName={name} document={document} />
			</PreviewSection>

			<InfoSection>
				<div>
					<InfoSectionHeader>
						<Typography variant="h5">Informations</Typography>

						<Stack justifyContent="center" alignItems="center">
							<IconButton onClick={onClose}>
								<Icon path={mdiClose} />
							</IconButton>
						</Stack>
					</InfoSectionHeader>

					<InfoLine label="Nom" value={<Typography>{name}</Typography>} />

					<InfoLine label="Type" value={<Typography>{fileType}</Typography>} />

					<InfoLine label="Exercice" value={<Typography fontWeight={800}>{fiscalYearName}</Typography>} />
				</div>

				<div>
					<Button
						disabled={!document}
						onClick={downloadDocument}
						startIcon={<Icon path={mdiDownload} />}
						variant="contained"
					>
						Télécharger
					</Button>
				</div>
			</InfoSection>
		</StyledDialog>
	);
};

export default DocumentPreviewDialog;
