import { useQuery } from '@apollo/client';
import { FileUpload, TableFilterType } from '@elipssolution/harfang';
import { mdiFileCheckOutline, mdiFileClockOutline } from '@mdi/js';
import { Stack, Typography, styled } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';

import KpiCard from './KpiCard';
import StorageUploadProvider, { useStorageUploadContext } from './StorageUploadProvider';
import StorageUploadTable, { DocumentUploadFilterType } from './StorageUploadTable';
import { FETCH_DOCUMENT_KPI, FetchDocumentKpiType } from '../../api/documentKpi';
import DocumentUploadDialog from '../../components/DocumentUploadDialog';

const MAX_FILES = 50;
const MAX_FILE_SIZE = 20; // Mo

const KPI_PENDING_GAUGE = [
	{
		min: 7,
		max: 14,
		color: 'orange',
	},
	{
		min: 15,
		color: 'red',
	},
];

const TableWrapper = styled('div')(({ theme: { spacing } }) => ({
	height: '100%',
	gap: spacing(2),

	display: 'flex',
	flexDirection: 'column',
}));

const StorageUpload = () => {
	const { tableInstance } = useStorageUploadContext();

	const [isDocumentUploadDialogOpen, setIsDocumentUploadDialogOpen] = useState(false);

	const [filter, setFilter] = useState<TableFilterType<DocumentUploadFilterType>>();
	const [uploadedFiles, setUploadedFiles] = useState<File[] | null>(null);
	const { createdBy, tag, ...rest } = filter || {};

	const {
		data,
		error,
		loading,
		refetch: refetchKpi,
	} = useQuery<FetchDocumentKpiType>(FETCH_DOCUMENT_KPI, {
		variables: {
			...(filter && {
				filter: {
					...(createdBy && { createdBy: { eq: createdBy?.eq?.email } }),
					...(tag && { tagId: { eq: tag?.eq?.id } }),
					...rest,
				},
			}),
		},
	});

	const { documentPendingKpi, documentProcessedKpi } = useMemo(() => {
		const { document_documentKpi } = data ?? {};
		const { documentPending, documentProcessed } = document_documentKpi ?? {};

		return {
			documentPendingKpi: documentPending ?? 0,
			documentProcessedKpi: documentProcessed ?? 0,
		};
	}, [data]);

	const handleFileUploadChange = useCallback((files: File[]) => {
		setUploadedFiles(files);
		setIsDocumentUploadDialogOpen(true);
	}, []);

	const closeDocumentUploadDialog = useCallback(() => {
		setIsDocumentUploadDialogOpen(false);
		setUploadedFiles(null);
	}, []);

	const refreshDatas = useCallback(() => {
		tableInstance.reload();
		refetchKpi().catch((e) => {
			throw e;
		});
	}, [refetchKpi, tableInstance]);

	return (
		<>
			<Typography paddingBottom={1} variant="h6">
				Dépôt
			</Typography>
			<TableWrapper>
				<Stack direction="row" gap={2}>
					<FileUpload
						onChange={handleFileUploadChange}
						maxFileSize={MAX_FILE_SIZE}
						maxFiles={MAX_FILES}
						showInformations
					/>
					<KpiCard
						gauge={KPI_PENDING_GAUGE}
						error={!!error}
						kpi={documentPendingKpi}
						iconPath={mdiFileClockOutline}
						label="En attente"
						loading={loading}
					/>
					<KpiCard
						color="secondary"
						error={!!error}
						kpi={documentProcessedKpi}
						iconPath={mdiFileCheckOutline}
						label="Traités"
						loading={loading}
					/>
				</Stack>

				<StorageUploadTable onDocumentUploadDeletion={refreshDatas} onFilterSubmit={setFilter} />
			</TableWrapper>

			<DocumentUploadDialog
				maxFiles={MAX_FILES}
				maxFileSize={MAX_FILE_SIZE}
				onClose={closeDocumentUploadDialog}
				onUploadSuccess={refreshDatas}
				open={isDocumentUploadDialogOpen}
				uploadedFiles={uploadedFiles}
			/>
		</>
	);
};

const StorageUploadWrapper = () => (
	<StorageUploadProvider>
		<StorageUpload />
	</StorageUploadProvider>
);

export default StorageUploadWrapper;
