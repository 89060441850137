import {
	Table,
	TableAutocompleteFilterDefinitionType,
	TableColumnType,
	TableFilterType,
	TableGenericFilterInputType,
	TableInstance,
	TableOrderByType,
} from '@elipssolution/harfang';
import { useMemo } from 'react';

import useBankDataSource from '../../hooks/useBankDataSource';
import { BankType } from '../../types/bank';
import { BillExchangeFilterType, BillExchangeType } from '../../types/billExchange';

type BankFilterValueType = Required<TableAutocompleteFilterDefinitionType<BankType>['output']>;

type BillExchangeTableProps = {
	bankFilterValue?: BankFilterValueType;
	columns: TableColumnType<BillExchangeType>[];
	dataSource: (
		limit: number,
		offset: number,
		search?: string,
		orderBy?: TableOrderByType<BillExchangeType>,
		filter?: TableFilterType<BillExchangeFilterType>,
	) => Promise<{ items: BillExchangeType[]; count: number }>;
	onBankFilterChange?: (filter?: BankFilterValueType) => void;
	onBillExchangeSelection?: (check: BillExchangeType) => void;
	tableInstance: TableInstance;
	title: string;
};

const BillExchangeTable = ({
	bankFilterValue,
	columns,
	dataSource,
	onBankFilterChange,
	onBillExchangeSelection,
	tableInstance,
	title,
}: BillExchangeTableProps) => {
	const { dataSource: banksDataSource } = useBankDataSource();

	const filters: TableGenericFilterInputType<BillExchangeFilterType> = useMemo(
		() => ({
			bank: {
				label: 'Banque',
				type: 'autocomplete',
				dataSource: banksDataSource,
				getOptionLabel: ({ name }: BankType) => name,
				onChange: (filterValue?: BankFilterValueType) => onBankFilterChange?.(filterValue ?? undefined),
				value: bankFilterValue,
			},
			documentDate: {
				label: 'Saisie entre',
				type: 'date',
			},
			amount: {
				label: 'Montant',
				type: 'currency',
			},
		}),
		[banksDataSource, onBankFilterChange, bankFilterValue],
	);

	return (
		<Table<BillExchangeType, BillExchangeFilterType>
			columns={columns}
			dataSource={dataSource}
			filter={onBankFilterChange ? filters : undefined}
			onRowClick={onBillExchangeSelection}
			style={{
				height: '100%',
			}}
			table={tableInstance}
			title={title}
			enableSearch={!!onBankFilterChange}
		/>
	);
};

export default BillExchangeTable;
