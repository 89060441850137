import { NetworkStatus } from '@apollo/client';
import { Icon, TableColumnType, TableInstance } from '@elipssolution/harfang';
import { mdiAlertCircle } from '@mdi/js';
import { alpha, Box, Stack, styled, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { FormattedNumber } from 'react-intl';

import useSynchronizedBillExchangesDataSource from './hooks/useSynchronizedBillExchangesDataSource';
import BillExchangeTable from './BillExchangeTable';
import { BankType } from '../../types/bank';
import { BillExchangeType } from '../../types/billExchange';

const ErrorCell = styled('div')(({ theme: { palette, shape, spacing } }) => ({
	flex: 1,
	height: 30,

	backgroundColor: alpha(palette.error.main, 0.2),
	color: palette.error.main,
	border: `1px solid ${palette.error.main}`,
	borderRadius: shape.borderRadius * 2,

	display: 'flex',
	alignItems: 'center',
	gap: spacing(1),

	paddingLeft: spacing(0.5),
}));

const columns: TableColumnType<BillExchangeType>[] = [
	{
		field: 'documentDate',
		flexGrow: 0,
		key: 'documentDate',
		render: ({ documentDate }) => documentDate.toLocaleDateString('fr-FR'),
		sortable: true,
		title: 'Date',
		width: 100,
	},
	{
		key: 'bank',
		render: ({ bank }) =>
			bank?.name ?? (
				<ErrorCell>
					<Icon path={mdiAlertCircle} />
					<Typography variant="caption">Banque manquante</Typography>
				</ErrorCell>
			),
		title: 'Banque',
		width: 125,
	},
	{
		key: 'accountCode',
		render: ({ account: { code } }) => code,
		title: 'Code aux.',
		width: 100,
	},
	{
		field: 'name',
		key: 'name',
		sortable: true,
		title: 'Libellé',
		width: 150,
	},
	{
		align: 'right',
		field: 'amount',
		key: 'amount',
		render: ({ amount }) => (
			<Stack paddingRight={1}>
				<FormattedNumber value={+amount} style="currency" currency="EUR" />
			</Stack>
		),
		sortable: true,
		title: 'Montant',
		width: 100,
	},
];

type SynchronizedBillExchangeTableProps = {
	selectedBank?: BankType;
	tableInstance: TableInstance;
};

const SynchronizedBillExchangeTable = ({ selectedBank, tableInstance }: SynchronizedBillExchangeTableProps) => {
	const [bankFilterValue, setBankFilterValue] = useState(selectedBank ? { eq: selectedBank } : undefined);

	const { dataSource: billExchangesDataSource, networkStatus } = useSynchronizedBillExchangesDataSource({
		bankFilterValue,
	});

	// Reload the table if the query has been refetched
	useEffect(() => {
		networkStatus === NetworkStatus.refetch && tableInstance.reload();
	}, [tableInstance, networkStatus]);

	useEffect(() => {
		selectedBank && setBankFilterValue({ eq: selectedBank });
	}, [selectedBank]);

	return (
		<Box flex={2} paddingBottom={2}>
			<BillExchangeTable
				bankFilterValue={bankFilterValue}
				columns={columns}
				dataSource={billExchangesDataSource}
				onBankFilterChange={setBankFilterValue}
				tableInstance={tableInstance}
				title="LCR"
			/>
		</Box>
	);
};

export default SynchronizedBillExchangeTable;
