import { ApolloError, useMutation } from '@apollo/client';
import {
	Button,
	ConfirmationDialog,
	DialogProps,
	Icon,
	SettingsGroup,
	SettingsItemCheckbox,
	SettingsItemTextField,
	Tooltip,
} from '@elipssolution/harfang';
import { mdiPencil, mdiPlus } from '@mdi/js';
import { styled } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { generateErrorInformations } from '../../../../utils/errorHandler';
import {
	CREATE_EXTERNAL_GROUP,
	CreateExternalGroupType,
	REMOVE_EXTERNAL_GROUP,
	RemoveExternalGroupType,
	UPDATE_EXTERNAL_GROUP,
	UpdateExternalGroupType,
} from '../../../api/externalGroup';
import { GroupType } from '../../../types/group';

const ActionWrapper = styled('div')({
	display: 'flex',
	flexDirection: 'row-reverse',
	justifyContent: 'space-between',
});

type FormFieldType = Pick<GroupType, 'isDefault' | 'name'>;

type ExternalGroupFormProps = {
	group?: Omit<GroupType, 'id' | 'name' | 'permissionModules'> & {
		id?: GroupType['id'];
		name?: GroupType['name'];
	};
	isFetchLoading: boolean;
	onError: (error: string) => void;
	onReplaceNeeded: (group: GroupType) => void;
	onRemoval: () => void;
};

const ExternalGroupForm = ({
	group,
	isFetchLoading = false,
	onError,
	onReplaceNeeded,
	onRemoval,
}: ExternalGroupFormProps) => {
	const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);
	const [confirmationDialogError, setConfirmationDialogError] = useState<string>();
	const { id: groupId, isAdministrator = false, isDefault, name: groupName } = group ?? {};

	const openConfirmationDialog = useCallback(() => setIsConfirmationDialogOpen(true), []);
	const closeConfirmationDialog = useCallback(() => {
		setIsConfirmationDialogOpen(false);
		setConfirmationDialogError(undefined);
	}, []);

	const {
		control,
		formState: { isDirty, isValid },
		handleSubmit,
		reset,
	} = useForm<FormFieldType>();

	const [createExternalGroup, { loading: isCreateExternalGroupLoading }] =
		useMutation<CreateExternalGroupType>(CREATE_EXTERNAL_GROUP);
	const [updateExternalGroup, { loading: isUpdateExternalGroupLoading }] =
		useMutation<UpdateExternalGroupType>(UPDATE_EXTERNAL_GROUP);
	const [removeExternalGroup, { loading: isRemoveExternalGroupLoading }] = useMutation<RemoveExternalGroupType>(
		REMOVE_EXTERNAL_GROUP,
		{
			onCompleted: onRemoval,
			onError: (mutationError) =>
				setConfirmationDialogError(
					generateErrorInformations({ error: mutationError, resource: 'removeExternalGroup' }).message,
				),
		},
	);

	const isLoading = useMemo(
		() =>
			isFetchLoading || isCreateExternalGroupLoading || isUpdateExternalGroupLoading || isRemoveExternalGroupLoading,
		[isCreateExternalGroupLoading, isFetchLoading, isRemoveExternalGroupLoading, isUpdateExternalGroupLoading],
	);

	const isMutationButtonDisabled = useMemo(
		() => isRemoveExternalGroupLoading || !isDirty || !isValid || isAdministrator,
		[isRemoveExternalGroupLoading, isDirty, isValid, isAdministrator],
	);

	const handleRemoval = useCallback(
		() =>
			removeExternalGroup({
				variables: {
					id: groupId,
				},
			}),
		[removeExternalGroup, groupId],
	);

	const onSubmit = useCallback(
		(values: FormFieldType) => {
			const modeMutationInfoMap = new Map([
				['create', { inputValues: values, mutation: createExternalGroup, mutationName: 'createExternalGroup' }],
				[
					'update',
					{
						inputValues: {
							...values,
							id: groupId,
						},
						mutation: updateExternalGroup,
						mutationName: 'updateExternalGroup',
					},
				],
			]);

			const { mutation, mutationName = '', inputValues } = modeMutationInfoMap.get(groupId ? 'update' : 'create') || {};

			return mutation?.({
				variables: {
					[`${mutationName}Input`]: inputValues,
				},
			})
				.then(({ data }) => {
					if (!data) return;

					const { name, isDefault: isDefaultUpdated } = values;

					reset(values);

					if (name !== groupName || isDefaultUpdated) {
						const { id }: { id: string } = data[`${mutationName}` as keyof typeof data];

						onReplaceNeeded({
							...values,
							isAdministrator,
							id,
						});
					}
				})
				.catch((mutationError: ApolloError) =>
					onError(generateErrorInformations({ error: mutationError, resource: mutationName }).message),
				);
		},
		[createExternalGroup, groupId, updateExternalGroup, reset, groupName, onReplaceNeeded, isAdministrator, onError],
	);

	const startIcon = useMemo(() => <Icon path={groupId ? mdiPencil : mdiPlus} />, [groupId]);

	const disabledTooltipContent = useMemo(() => {
		if (isAdministrator) return 'Vous ne pouvez pas modifier le groupe administrateur.';
		if (isDefault) return 'Vous ne pouvez pas modifier le groupe par défaut.';

		return undefined;
	}, [isAdministrator, isDefault]);

	// Setting form state
	useEffect(
		() =>
			reset({
				name: groupName,
				isDefault,
			}),
		[reset, groupName, isDefault],
	);
	const actionsDialog = useMemo(
		(): DialogProps['actionsDialog'] => [
			{
				disabled: isRemoveExternalGroupLoading,
				label: 'Annuler',
				onClick: closeConfirmationDialog,
			},
			{
				loading: isRemoveExternalGroupLoading,
				persistantErrorMessage: confirmationDialogError,
				color: 'error',
				label: 'Supprimer',
				onClick: handleRemoval,
				variant: 'contained',
			},
		],
		[closeConfirmationDialog, handleRemoval, confirmationDialogError, isRemoveExternalGroupLoading],
	);

	return (
		<>
			<SettingsGroup>
				<Controller
					control={control}
					render={({ field }) => (
						<SettingsItemTextField
							{...field}
							description="Nom du groupe."
							disabled={isAdministrator || isFetchLoading}
							label="Nom"
							required
						/>
					)}
					rules={{ required: true }}
					name="name"
				/>

				<Controller
					control={control}
					defaultValue={false}
					render={({ field: { value, ...field } }) => (
						<SettingsItemCheckbox
							{...field}
							checked={value}
							description="Si coché, le groupe est celui par défaut."
							disabled={isAdministrator || isDefault || isFetchLoading}
							disabledTooltipContent={disabledTooltipContent}
							label="Par défaut"
						/>
					)}
					name="isDefault"
				/>
			</SettingsGroup>

			{!isAdministrator && !isFetchLoading && (
				<ActionWrapper>
					<Button
						disabled={isMutationButtonDisabled}
						onClick={handleSubmit(onSubmit)}
						startIcon={startIcon}
						variant="contained"
					>
						{groupId ? 'Modifier' : 'Ajouter'}
					</Button>

					{groupId && (
						<Tooltip content={isDefault ? 'Un groupe par défaut ne peux pas être supprimé' : ''}>
							<div>
								<Button
									color="error"
									disabled={isLoading || isDefault}
									onClick={openConfirmationDialog}
									variant="outlined"
								>
									Supprimer
								</Button>
							</div>
						</Tooltip>
					)}
				</ActionWrapper>
			)}

			{groupName && !isDefault && !isAdministrator && (
				<ConfirmationDialog
					actionsDialog={actionsDialog}
					open={isConfirmationDialogOpen}
					onClose={closeConfirmationDialog}
					title={`Êtes-vous sûr de vouloir supprimer le groupe ${groupName} ?`}
				/>
			)}
		</>
	);
};

export default ExternalGroupForm;
