import {
	Table,
	TableColumnType,
	TableFilterType,
	TableGenericFilterInputType,
	TableInstance,
	TableOrderByType,
} from '@elipssolution/harfang';
import { useMemo } from 'react';

import useBankDataSource from '../../hooks/useBankDataSource';
import { BankType } from '../../types/bank';
import { CheckFilterType, CheckType } from '../../types/check';

type BankFilterValueType = { eq: BankType };

type CheckTableProps = {
	bankFilterValue?: BankFilterValueType;
	columns: TableColumnType<CheckType>[];
	dataSource: (
		limit: number,
		offset: number,
		search?: string,
		orderBy?: TableOrderByType<CheckType>,
		filter?: TableFilterType<CheckFilterType>,
	) => Promise<{ items: CheckType[]; count: number }>;
	onBankFilterChange?: (filter?: BankFilterValueType) => void;
	onCheckSelection: (check: CheckType) => void;
	tableInstance: TableInstance;
	title: string;
};

const CheckTable = ({
	bankFilterValue,
	columns,
	dataSource,
	onBankFilterChange,
	onCheckSelection,
	tableInstance,
	title,
}: CheckTableProps) => {
	const { dataSource: banksDataSource } = useBankDataSource();

	const filters: TableGenericFilterInputType<CheckFilterType> = useMemo(
		() => ({
			bank: {
				label: 'Banque',
				type: 'autocomplete',
				dataSource: banksDataSource,
				getOptionLabel: ({ name }: BankType) => name,
				onChange: (filterValue?: BankFilterValueType) => onBankFilterChange?.(filterValue ?? undefined),
				value: bankFilterValue,
			},
			documentDate: {
				label: 'Saisie entre',
				type: 'date',
			},
			amount: {
				label: 'Montant',
				type: 'currency',
			},
		}),
		[banksDataSource, onBankFilterChange, bankFilterValue],
	);

	return (
		<Table<CheckType, CheckFilterType>
			columns={columns}
			dataSource={dataSource}
			filter={onBankFilterChange ? filters : undefined}
			onRowClick={onCheckSelection}
			style={{
				height: '100%',
			}}
			table={tableInstance}
			title={title}
			enableSearch={!!onBankFilterChange}
		/>
	);
};

export default CheckTable;
