import { gql } from '@apollo/client';

import { SettingStorageDownloadType } from '../types/settingStorage';

// QUERIES

export type FetchStorageDownloadType = {
	document_settingStorageDownload: SettingStorageDownloadType;
};

export const FETCH_STORAGE_DOWNLOAD = gql`
	query Document_settingStorageDownload {
		document_settingStorageDownload {
			configuration
			connector
			exclusions
			id
			levelName1
			levelName2
			levelName3
			folders
		}
	}
`;

// MUTATIONS

export type CreateStorageDownloadType = {
	document_createSettingStorageDownload: SettingStorageDownloadType;
};

export const CREATE_STORAGE_DOWNLOAD = gql`
	mutation Document_createSettingStorageDownload(
		$createSettingStorageDownloadInput: document_CreateSettingStorageDownloadInput!
	) {
		document_createSettingStorageDownload(createSettingStorageDownloadInput: $createSettingStorageDownloadInput) {
			id
		}
	}
`;

export type UpdateStorageDownloadType = {
	document_updateSettingStorageDownload: SettingStorageDownloadType;
};

export const UPDATE_STORAGE_DOWNLOAD = gql`
	mutation Document_updateSettingStorageDownload(
		$updateSettingStorageDownloadInput: document_UpdateSettingStorageDownloadInput!
	) {
		document_updateSettingStorageDownload(updateSettingStorageDownloadInput: $updateSettingStorageDownloadInput) {
			id
		}
	}
`;

export const REMOVE_STORAGE_DOWNLOAD = gql`
	mutation Document_removeSettingStorageDownload($id: ID!) {
		document_removeSettingStorageDownload(id: $id) {
			id
		}
	}
`;
