import { SettingsGroup, SettingsItemTextField } from '@elipssolution/harfang';
import { useMemo } from 'react';
import { Control, Controller, UseFormWatch } from 'react-hook-form';

import ExclusionsForm from './ExclusionsForm';
import ConnectorFieldsForm from '../../components/ConnectorFieldsForm';
import { ConnectorType } from '../../types/connector';
import { StorageDownloadFormType } from '../../types/storageDownloadFormType';

type StorageDownloadConfigFormProps = {
	control: Control<StorageDownloadFormType>;
	watch: UseFormWatch<StorageDownloadFormType>;
	connector: ConnectorType;
	validateRequiredFolders: (value?: Record<string, string | undefined> | null) => boolean;
};

const StorageDownloadConfigForm = ({
	control,
	watch,
	connector,
	validateRequiredFolders,
}: StorageDownloadConfigFormProps) => {
	const foldersForm = watch('folders');

	const { isLevel1Required, isLevel2Required, isLevel3Required } = useMemo(() => {
		const serializedFoldersForm = JSON.stringify(foldersForm);
		return {
			isLevel1Required: serializedFoldersForm?.includes('level1'),
			isLevel2Required: serializedFoldersForm?.includes('level2'),
			isLevel3Required: serializedFoldersForm?.includes('level3'),
		};
	}, [foldersForm]);

	return (
		<>
			<SettingsGroup label="Arborescences" actionsWidth={550}>
				<Controller
					control={control}
					render={({ field }) => <ConnectorFieldsForm fields={connector?.folders} {...field} />}
					name="folders"
					rules={{
						validate: validateRequiredFolders,
					}}
				/>
			</SettingsGroup>

			<SettingsGroup label="Niveaux">
				<Controller
					control={control}
					render={({ field: { value, ...field } }) => (
						<SettingsItemTextField
							{...field}
							invalid={!value && isLevel1Required}
							label="Libellé du niveau 1"
							required={isLevel1Required}
							value={value}
						/>
					)}
					rules={{ required: isLevel1Required }}
					name="levelName1"
				/>
				<Controller
					control={control}
					render={({ field: { value, ...field } }) => (
						<SettingsItemTextField
							{...field}
							invalid={!value && isLevel2Required}
							label="Libellé du niveau 2"
							required={isLevel2Required}
							value={value}
						/>
					)}
					rules={{ required: isLevel2Required }}
					name="levelName2"
				/>
				<Controller
					control={control}
					render={({ field: { value, ...field } }) => (
						<SettingsItemTextField
							{...field}
							invalid={!value && isLevel3Required}
							label="Libellé du niveau 3"
							required={isLevel3Required}
							value={value}
						/>
					)}
					rules={{ required: isLevel3Required }}
					name="levelName3"
				/>
			</SettingsGroup>

			<Controller
				control={control}
				render={({ field: { onChange, value } }) => <ExclusionsForm onChange={onChange} exclusions={value} />}
				name="exclusions"
			/>
		</>
	);
};

export default StorageDownloadConfigForm;
