import { Button, Icon, Select, TextField } from '@elipssolution/harfang';
import { mdiPencil, mdiPlus } from '@mdi/js';
import { Checkbox, FormControl, FormControlLabel, styled } from '@mui/material';
import { ChangeEvent, useCallback, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { AccountTypeEnum, AccountTypeLabelEnum } from '../../../../../types/account';
import { AnalyticalDimensionType } from '../../../../../types/analyticalDimension';
import { AnalyticalSectionType } from '../../../../../types/analyticalSection';
import { SettingDomainTemplateLineType, SettingTemplateLineType } from '../../../../../types/settingTemplate';
import { TemplateType } from '../../../../../types/template';
import { getFormResettedValues } from '../../../../../utils/formHandler';

const FormRow = styled('div')(({ theme: { spacing } }) => ({
	display: 'flex',
	alignItems: 'center',
	gap: spacing(2),

	marginTop: spacing(2),

	'& > div': {
		flex: 1,
	},
}));

type SelectsType = {
	value: string;
	label: string;
};

type FormFieldType = Omit<SettingDomainTemplateLineType, 'id'>;

type TemplateLineFormSectionProps = {
	analyticalDimensionCode?: AnalyticalDimensionType['code'];
	defaultEntryName?: TemplateType['defaultEntryName'];
	directions: SelectsType[];
	onSubmit: (values: FormFieldType) => void;
	resetUpdateTemplateLine: () => void;
	templateLine?: SettingTemplateLineType;
};

const accountTypeOptions = Object.values(AccountTypeEnum);

const TemplateLineFormSection = ({
	analyticalDimensionCode,
	defaultEntryName,
	directions,
	onSubmit,
	resetUpdateTemplateLine,
	templateLine,
}: TemplateLineFormSectionProps) => {
	const {
		control,
		formState: { dirtyFields, isDirty, isValid },
		getValues,
		handleSubmit,
		reset,
		setValue,
		watch,
	} = useForm<FormFieldType>({
		mode: 'onBlur',
	});

	const lineType = watch('type');
	const defaultTransactionName = watch('defaultTransactionName');

	const resetForm = useCallback(() => {
		const values: { [key: string]: unknown } = getValues();

		const resettedValues = getFormResettedValues({ values });

		reset({
			...resettedValues,
		});
	}, [getValues, reset]);

	const handleFormSubmit = useCallback(
		(values: FormFieldType) => {
			onSubmit(values);

			resetForm();
		},
		[onSubmit, resetForm],
	);

	const resetUpdating = useCallback(() => {
		resetForm();
		resetUpdateTemplateLine();
	}, [resetForm, resetUpdateTemplateLine]);

	// Apply the prop template line to the form
	useEffect(() => {
		const { id, rank, ...rest } =
			(templateLine as SettingTemplateLineType & {
				id: string;
			}) ?? {};

		id
			? reset({
					...rest,
			  })
			: resetForm();
	}, [reset, resetForm, templateLine]);

	// Sets the default value of defaultTransactionName and type when the form is dirty
	useEffect(() => {
		if (
			isDirty &&
			!Object.keys(dirtyFields).includes('defaultTransactionName') &&
			!Object.keys(dirtyFields).includes('type')
		) {
			setValue('defaultTransactionName', defaultEntryName ?? '');
			setValue('type', AccountTypeEnum.GENERAL);
		}
	}, [setValue, defaultEntryName, defaultTransactionName, dirtyFields, isDirty, lineType]);

	// Reset the analyticalSectionCode field if there is no analytical dimension or the line type is set to subaccount
	useEffect(() => {
		(!analyticalDimensionCode || lineType === AccountTypeEnum.SUBACCOUNT) &&
			setValue('analyticalSectionCode', undefined);
	}, [setValue, analyticalDimensionCode, lineType]);

	return (
		<>
			<FormRow>
				<Controller
					name="name"
					control={control}
					render={({ field }) => <TextField {...field} label="Description" required />}
					rules={{ required: true, pattern: /(.|\s)*\S(.|\s)*/ }}
				/>
				<Controller
					name="direction"
					control={control}
					rules={{ required: true }}
					render={({ field: { value, onChange, ...field } }) => {
						const handleChange = (newValue?: SelectsType) => {
							onChange(newValue?.value);
						};

						return (
							<Select<SelectsType>
								{...field}
								options={directions}
								label="Sens *"
								onChange={handleChange}
								renderOption={({ label }: SelectsType) => label}
								renderValue={({ label }: SelectsType) => label}
								value={directions.find((direction) => direction.value === value)}
								disableClearable
								required
							/>
						);
					}}
				/>
				<Controller
					name="defaultTransactionName"
					control={control}
					rules={{ required: true, pattern: /(.|\s)*\S(.|\s)*/ }}
					render={({ field }) => <TextField {...field} label="Libellé par défaut" required />}
				/>
				<Controller
					name="type"
					control={control}
					rules={{ required: true }}
					render={({ field: { onChange, ...field } }) => {
						const handleChange = (newValue?: AccountTypeEnum) => {
							onChange(newValue);
							setValue('accountCode', undefined);
							setValue('subaccountCode', undefined);
						};

						return (
							<Select<AccountTypeEnum>
								{...field}
								onChange={handleChange}
								options={accountTypeOptions}
								label="Type *"
								renderOption={(option) => AccountTypeLabelEnum[option]}
								renderValue={(option) => AccountTypeLabelEnum[option]}
								disableClearable
								required
							/>
						);
					}}
				/>
				<Controller
					name="accountCode"
					control={control}
					rules={{ required: true }}
					render={({ field }) => <TextField {...field} label="Compte" required />}
				/>
			</FormRow>
			<FormRow>
				<Controller
					name="analyticalSectionCode"
					control={control}
					render={({ field: { onChange, ...field } }) => {
						const handleChange = (value?: AnalyticalSectionType['code']) => {
							onChange(value);
							setValue('isAnalyticalEnabled', !!value);
						};

						return (
							<TextField
								{...field}
								disabled={!analyticalDimensionCode || lineType === AccountTypeEnum.SUBACCOUNT}
								label="Section analytique"
								onChange={handleChange}
							/>
						);
					}}
				/>
				<Controller
					name="subaccountCode"
					control={control}
					render={({ field }) => (
						<TextField {...field} disabled={lineType !== AccountTypeEnum.SUBACCOUNT} label="Compte auxiliaire" />
					)}
				/>

				<Controller
					name="subaccountPrefix"
					control={control}
					render={({ field, fieldState: { error } }) => (
						<TextField
							{...field}
							disabled={lineType !== AccountTypeEnum.SUBACCOUNT}
							label="Préfixe auxiliaire"
							helperText={error?.message}
						/>
					)}
				/>
				<Controller
					control={control}
					defaultValue={false}
					name="isAnalyticalEnabled"
					render={({ field: { value, onChange, ...field } }) => {
						const handleCheckboxCheck = ({ target: { checked } }: ChangeEvent<HTMLInputElement>) => onChange(checked);
						return (
							<FormControl>
								<FormControlLabel
									disabled={!analyticalDimensionCode}
									control={<Checkbox {...field} checked={value} onChange={handleCheckboxCheck} />}
									label="Utilise l'analytique"
								/>
							</FormControl>
						);
					}}
				/>
			</FormRow>
			<FormRow>
				<Button
					disabled={!isDirty || !isValid}
					onClick={handleSubmit(handleFormSubmit)}
					startIcon={<Icon path={templateLine ? mdiPencil : mdiPlus} />}
					variant="contained"
				>
					{templateLine ? 'Modifier la ligne de modèle' : 'Ajouter une ligne de modèle'}
				</Button>
				{templateLine && (
					<Button onClick={resetUpdating} variant="text">
						Annuler la modification
					</Button>
				)}
			</FormRow>
		</>
	);
};

export default TemplateLineFormSection;
