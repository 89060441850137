import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Button, SettingsGroup, SettingsItemAutocomplete } from '@elipssolution/harfang';
import { styled } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import SettingsDialogPage from '../../../../../../components/SettingsDialogPage';
import { CustomerFileType } from '../../../../../../types/customerFile';
import {
	FETCH_COUNTERPART_ACCOUNTS_BY_CUSTOMER_FILE,
	FetchCounterpartAccountsByCustomerFileType,
} from '../../../../api/account';
import {
	CreateCustomerFileBillExchangeSettingCounterpartAccountType,
	CREATE_CUSTOMER_FILE_BILL_EXCHANGE_SETTING_COUNTERPART_ACCOUNT,
	FetchCustomerFileBillExchangeSettingCounterpartAccountType,
	FETCH_CUSTOMER_FILE_BILL_EXCHANGE_SETTING_COUNTERPART_ACCOUNT,
} from '../../../../api/settingBillExchangeCounterpartAccount';
import { AccountType } from '../../../../types/account';

const ActionWrapper = styled('div')({
	display: 'flex',
	flexDirection: 'row-reverse',
	justifyContent: 'space-between',
});

const ErrorWrapper = styled('div')(({ theme: { palette, shape } }) => ({
	height: 36,
	width: '50%',

	display: 'grid',
	placeItems: 'center',

	color: palette.error.main,
	backgroundColor: `${palette.error.main}1A`,
	borderRadius: shape.borderRadius * 2,
}));

type FormFieldType = {
	counterpartAccount: { code: AccountType['code'] };
};

type BillExchangeCounterpartProps = {
	customerFileId?: CustomerFileType['id'];
};

const BillExchangeCounterpartAccount = ({ customerFileId }: BillExchangeCounterpartProps) => {
	const {
		control,
		formState: { isDirty, isValid },
		handleSubmit,
		setValue,
		setError: setFormError,
		clearErrors,
	} = useForm<FormFieldType>();

	const isFormValid = useMemo(() => isDirty && isValid, [isDirty, isValid]);

	const [error, setError] = useState<string>();

	useQuery<FetchCustomerFileBillExchangeSettingCounterpartAccountType>(
		FETCH_CUSTOMER_FILE_BILL_EXCHANGE_SETTING_COUNTERPART_ACCOUNT,
		{
			variables: {
				id: customerFileId,
			},
			onCompleted: ({
				quickentry_settingCustomerFileBillExchangeCounterpartAccount: {
					data: { accountCode },
					error: errorValue,
				},
			}) => {
				if (accountCode) {
					setValue('counterpartAccount', { code: accountCode });
				}
				if (errorValue) {
					setFormError('counterpartAccount', { message: `${errorValue.fieldValue} n'existe pas dans le dossier` });
				}
			},
		},
	);

	const [createBillExchangeCounterpartAccount] =
		useMutation<CreateCustomerFileBillExchangeSettingCounterpartAccountType>(
			CREATE_CUSTOMER_FILE_BILL_EXCHANGE_SETTING_COUNTERPART_ACCOUNT,
			{
				onError: (mutationError) => {
					setError(mutationError.message);
				},
			},
		);

	const onSubmit = ({ counterpartAccount }: FormFieldType) =>
		createBillExchangeCounterpartAccount({
			variables: {
				createSettingCustomerFileBillExchangeCounterpartAccountInput: {
					accountCode: counterpartAccount.code,
					customerFileId,
				},
			},
		});

	const [fetchCounterpartAccountsByCustomerFile] = useLazyQuery<FetchCounterpartAccountsByCustomerFileType>(
		FETCH_COUNTERPART_ACCOUNTS_BY_CUSTOMER_FILE,
	);

	const counterpartAccountCodesDataSource = useCallback(
		async (
			limit: number,
			offset: number,
			search?: string,
		): Promise<{
			count: number;
			items: { code: AccountType['code'] }[];
		}> => {
			const { data, error: queryError } = await fetchCounterpartAccountsByCustomerFile({
				variables: {
					page: {
						limit,
						offset,
					},
					customerFileId,
					search,
				},
			});

			if (queryError) {
				throw queryError;
			}

			const {
				quickentry_counterpartAccounts: { count = 0, items = [] },
			} = data ?? {
				quickentry_counterpartAccounts: {},
			};

			return {
				count,
				items: items.map(({ code }) => ({ code })),
			};
		},
		[customerFileId, fetchCounterpartAccountsByCustomerFile],
	);

	return (
		<SettingsDialogPage title="Contrepartie LCR">
			<SettingsGroup>
				<Controller
					control={control}
					name="counterpartAccount"
					render={({ field: { onChange, ...field }, fieldState: { error: errorField } }) => {
						const handleCounterpartAccountCodeChange = (counterpartAccountCode?: { code: AccountType['code'] }) => {
							onChange?.(counterpartAccountCode);
							clearErrors('counterpartAccount');
						};
						return (
							<SettingsItemAutocomplete<{ code: AccountType['code'] }>
								{...field}
								dataKey="code"
								onChange={handleCounterpartAccountCodeChange}
								dataSource={counterpartAccountCodesDataSource}
								getOptionLabel={({ code }) => `${code}`}
								label="Compte de contrepartie"
								invalid={!!errorField}
								helperText={errorField ? "Le compte n'existe pas au dossier" : ''}
								disableClearable
							/>
						);
					}}
				/>
			</SettingsGroup>

			<ActionWrapper>
				<Button disabled={!isFormValid} onClick={handleSubmit(onSubmit)} variant="contained">
					Valider
				</Button>

				{error && <ErrorWrapper>{error}</ErrorWrapper>}
			</ActionWrapper>
		</SettingsDialogPage>
	);
};

export default BillExchangeCounterpartAccount;
