import { NetworkStatus, useLazyQuery } from '@apollo/client';
import {
	Chip,
	CircularProgress,
	Icon,
	IconButton,
	Table,
	TableAutocompleteFilterDefinitionType,
	TableColumnType,
	TableDateFilterDefinitionType,
	TableFilterType,
	TableGenericFilterInputType,
	TableOrderByType,
	TableSelectFilterDefinitionType,
	Tooltip,
} from '@elipssolution/harfang';
import { mdiAlertCircle, mdiDelete } from '@mdi/js';
import { Stack } from '@mui/material';
import { useSession as useNextAuthSession } from 'next-auth/react';
import { MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';

import DocumentUploadPreviewDialog from './DocumentUploadPreviewDialog';
import { useStorageUploadContext } from './StorageUploadProvider';
import { useSession } from '../../../../src/components/SessionProvider';
import { getFileBlob } from '../../../../src/utils/file';
import { FETCH_DOCUMENT_UPLOADS, FetchDocumentUploadsType } from '../../api/documentUpload';
import { FETCH_DOCUMENT_USERS, FetchDocumentUserType } from '../../api/documentUser';
import { FETCH_USED_TAGS, FetchUsedTagsType } from '../../api/tag';
import StatusChip from '../../components/StatusChip';
import {
	DocumentUploadStatusEnum,
	DocumentUploadType,
	toDocumentUploadStatusLabelEnum,
} from '../../types/documentUpload';
import { DocumentUserType } from '../../types/documentUser';
import { TagType } from '../../types/tag';

export type DocumentUploadFilterType = {
	createdAt: TableDateFilterDefinitionType;
	createdBy: TableAutocompleteFilterDefinitionType<DocumentUserType>;
	status: TableSelectFilterDefinitionType<DocumentUploadStatusEnum>;
	tag: TableAutocompleteFilterDefinitionType<TagType>;
};

type StorageUploadTableProps = {
	onDocumentUploadDeletion: () => void;
	onFilterSubmit: (filter?: TableFilterType<DocumentUploadFilterType>) => void;
};

const StorageUploadTable = ({ onDocumentUploadDeletion, onFilterSubmit }: StorageUploadTableProps) => {
	const { data: sessionData } = useNextAuthSession();
	const { access_token } = sessionData ?? {};
	const { customerFile: sessionCustomerFile } = useSession();
	const { tableInstance } = useStorageUploadContext();

	const [selectedDocumentUploadId, setSelectedDocumentUploadId] = useState<DocumentUploadType['id']>();
	const [fetchError, setFetchError] = useState<string>();
	const [isFetchLoading, setIsFetchLoading] = useState(false);
	const [document, setDocument] = useState<Blob>();
	const [deleteError, setDeleteError] = useState<{
		documentUploadId: DocumentUploadType['id'];
		message: string;
	}>();

	const [fetchDocumentUsers] = useLazyQuery<FetchDocumentUserType>(FETCH_DOCUMENT_USERS);
	const [fetchUsedTags] = useLazyQuery<FetchUsedTagsType>(FETCH_USED_TAGS);
	const [fetchDocumentUploads, { networkStatus }] = useLazyQuery<FetchDocumentUploadsType>(FETCH_DOCUMENT_UPLOADS, {
		notifyOnNetworkStatusChange: true,
	});

	const deleteFile = useCallback(
		async (id: DocumentUploadType['id']) => {
			if (!access_token || !sessionCustomerFile?.id) return;

			const headers = new Headers();
			headers.append('Authorization', `Bearer ${access_token}`);
			headers.append('customer-file-id', sessionCustomerFile.id);

			const response = await fetch(`/document-uploads?id=${id}`, {
				method: 'DELETE',
				headers,
			});

			let timeoutDelay = 5000;

			try {
				if (!response.ok) {
					const message = await response.text();

					if (message.includes("The document isn't anymore into the storage upload.")) {
						setDeleteError({ documentUploadId: id, message: 'Le document a déjà été synchronisé' });
						throw new Error('Le document a déjà été synchronisé.');
					}

					throw new Error('Unable to delete file');
				}

				timeoutDelay = 1500;
			} finally {
				setTimeout(() => {
					onDocumentUploadDeletion();
					setDeleteError(undefined);
				}, timeoutDelay);
			}
		},
		[access_token, onDocumentUploadDeletion, sessionCustomerFile?.id],
	);

	const handleFileDeletion = useCallback(
		async (event: MouseEvent<HTMLButtonElement>, id: DocumentUploadType['id']) => {
			event.stopPropagation();

			await deleteFile(id);
		},
		[deleteFile],
	);

	const columns: TableColumnType<DocumentUploadType>[] = useMemo(
		() => [
			{
				field: 'name',
				key: 'name',
				sortable: true,
				title: 'Nom',
				width: 200,
			},
			{
				key: 'tag',
				render: ({ tag: { color, name } }) => <Chip color={color} label={name} />,
				title: 'Tag',
				width: 75,
			},
			{
				field: 'status',
				key: 'status',
				render: ({ status }) => <StatusChip status={status} />,
				sortable: true,
				title: 'Statut',
				width: 75,
			},
			{
				field: 'createdBy',
				key: 'createdBy',
				sortable: true,
				title: 'Déposé par',
				width: 200,
			},
			{
				field: 'createdAt',
				key: 'createdAt',
				render: ({ createdAt }) => new Date(createdAt)?.toLocaleDateString('fr-FR'),
				sortable: true,
				title: 'Déposé le',
				width: 50,
			},
			{
				key: 'actions',
				flexGrow: 0,
				render: ({ id, status }) => {
					const hasError = deleteError?.documentUploadId === id;

					if (selectedDocumentUploadId === id && isFetchLoading) {
						return (
							<Stack justifyContent="center" alignItems="center" width="100%">
								<CircularProgress />
							</Stack>
						);
					}

					if (selectedDocumentUploadId === id && fetchError) {
						return (
							<Tooltip color="error" content={fetchError} open={!!fetchError} placement="left">
								<Stack justifyContent="center" alignItems="center" width="100%">
									<Icon path={mdiAlertCircle} color="error" />
								</Stack>
							</Tooltip>
						);
					}

					return (
						(status === DocumentUploadStatusEnum.SENT || status === DocumentUploadStatusEnum.WAITING) && (
							<Tooltip color="error" content={hasError ? deleteError?.message : ''} open={hasError} placement="left">
								<IconButton
									error={hasError}
									onClick={(event) => handleFileDeletion(event, id)}
									sx={{
										...(hasError && { pointerEvents: 'none' }),
									}}
								>
									<Icon path={mdiDelete} />
								</IconButton>
							</Tooltip>
						)
					);
				},
				width: 40,
			},
		],
		[
			deleteError?.documentUploadId,
			deleteError?.message,
			fetchError,
			handleFileDeletion,
			isFetchLoading,
			selectedDocumentUploadId,
		],
	);

	const documentUploadsDataSource = useCallback(
		async (
			limit: number,
			offset: number,
			search?: string,
			orderBy?: TableOrderByType<DocumentUploadType>,
			filter?: TableFilterType<DocumentUploadFilterType>,
		): Promise<{
			count: number;
			items: DocumentUploadType[];
		}> => {
			const { field, order } = orderBy || {};
			const { tag, createdBy, ...restFilter } = filter || {};

			const { data, error } = await fetchDocumentUploads({
				variables: {
					filter: {
						...(createdBy && { createdBy: { eq: createdBy?.eq?.email } }),
						...(tag && { tagId: { eq: tag?.eq?.id } }),
						...restFilter,
					},
					...(orderBy && { orderBy: { field, order } }),
					page: {
						limit,
						offset,
					},
					search,
				},
			});

			if (error) {
				throw error;
			}

			const {
				document_documentUploads: { count = 0, items = [] },
			} = data ?? {
				document_documentUploads: {},
			};

			return {
				count,
				items,
			};
		},
		[fetchDocumentUploads],
	);

	const documentUsersDataSource = useCallback(
		async (
			limit: number,
			offset: number,
			search?: string,
		): Promise<{
			count: number;
			items: DocumentUserType[];
		}> => {
			const { data, error } = await fetchDocumentUsers({
				variables: {
					page: {
						limit,
						offset,
					},
					search,
				},
			});

			if (error) {
				throw error;
			}

			const {
				document_documentUploadUsers: { count = 0, items = [] },
			} = data ?? {
				document_documentUploadUsers: {},
			};

			return {
				count,
				items,
			};
		},
		[fetchDocumentUsers],
	);

	const tagsDataSource = useCallback(
		async (
			limit: number,
			offset: number,
			search?: string,
			orderBy?: TableOrderByType<TagType>,
		): Promise<{
			count: number;
			items: TagType[];
		}> => {
			const { field, order } = orderBy || {};

			const { data, error } = await fetchUsedTags({
				variables: {
					...(orderBy && { orderBy: { field, order } }),
					page: {
						limit,
						offset,
					},
					search,
				},
			});

			if (error) {
				throw error;
			}

			const {
				document_usedTags: { count = 0, items = [] },
			} = data ?? {
				document_usedTags: {},
			};

			return {
				count,
				items,
			};
		},
		[fetchUsedTags],
	);

	const filters: TableGenericFilterInputType<DocumentUploadFilterType> = useMemo(
		() => ({
			tag: {
				type: 'autocomplete',
				label: 'Tag',
				dataSource: tagsDataSource,
				getOptionLabel: ({ name }: TagType) => name,
			},
			status: {
				label: 'Statut',
				type: 'select',
				options: [DocumentUploadStatusEnum.PROCESSED, DocumentUploadStatusEnum.WAITING, DocumentUploadStatusEnum.SENT],
				renderOption: (value) => toDocumentUploadStatusLabelEnum(value),
				renderValue: (value) => toDocumentUploadStatusLabelEnum(value),
			},
			createdBy: {
				dataSource: documentUsersDataSource,
				label: 'Déposé par',
				getOptionLabel: ({ email }: DocumentUserType) => email,
				type: 'autocomplete',
			},
			createdAt: {
				label: 'Déposé entre',
				type: 'date',
			},
		}),
		[documentUsersDataSource, tagsDataSource],
	);

	const handleDocumentUploadClick = useCallback(
		({ id, status }: DocumentUploadType) => {
			if (
				status === DocumentUploadStatusEnum.PROCESSED ||
				fetchError ||
				isFetchLoading ||
				deleteError?.documentUploadId === id
			)
				return;

			setSelectedDocumentUploadId(id);
			setIsFetchLoading(true);

			getFileBlob(`/document-uploads?id=${id}`, access_token, sessionCustomerFile?.id)
				.then((data) => {
					setDocument(data);
					setIsFetchLoading(false);
				})
				.catch((err) => {
					setIsFetchLoading(false);
					setFetchError((err as Error).message);

					setTimeout(() => {
						onDocumentUploadDeletion();
						setFetchError(undefined);
						setSelectedDocumentUploadId(undefined);
					}, 5000);
				});
		},
		[
			fetchError,
			isFetchLoading,
			deleteError?.documentUploadId,
			access_token,
			sessionCustomerFile?.id,
			onDocumentUploadDeletion,
		],
	);

	const handleDocumentUploadPreviewDialogClose = () => {
		setSelectedDocumentUploadId(undefined);
		setDocument(undefined);
	};

	// Reload the table if the query has been refetched
	useEffect(() => {
		networkStatus === NetworkStatus.refetch && tableInstance.reload();
	}, [tableInstance, networkStatus]);

	return (
		<Stack flex={1}>
			<Table<DocumentUploadType, DocumentUploadFilterType>
				table={tableInstance}
				columns={columns}
				dataSource={documentUploadsDataSource}
				onFilterSubmit={onFilterSubmit}
				filter={filters}
				onRowClick={handleDocumentUploadClick}
				style={{
					height: '100%',
				}}
				title="Historique"
				enableSearch
			/>

			<DocumentUploadPreviewDialog
				documentUploadId={selectedDocumentUploadId}
				onClose={handleDocumentUploadPreviewDialogClose}
				document={document}
			/>
		</Stack>
	);
};

export default StorageUploadTable;
