import { DialogProps, ConfirmationDialog as HarfangConfirmationDialog } from '@elipssolution/harfang';
import { useCallback, useMemo, useState } from 'react';

type ConfirmationDialogProps = {
	/** The dialog is open */
	open: boolean;
	/** Title of dialog */
	title: string;
	/** Content of dialog */
	message?: string;
	/**
	 * The label of button confirm.
	 * @default Confirmer
	 */
	confirmLabel: string;
	/**
	 * The label of button cancel.
	 * @default Annuler
	 */
	cancelLabel?: string;
	/**	On cancel dialog */
	onCancel: () => void;
	/**
	 * The action triggered on confirm.
	 * @returns The promise of action if it is async.
	 */
	onConfirm: () => Promise<void> | void;
};

const ConfirmationDialog = ({
	open,
	title,
	message,
	confirmLabel = 'Confirmer',
	cancelLabel = 'Annuler',
	onConfirm,
	onCancel,
}: ConfirmationDialogProps) => {
	const [isLoading, setLoading] = useState(false);
	const [hasSucceeded, setSucceeded] = useState(false);
	const [error, setError] = useState<string>();

	const handleCancel = useCallback(() => {
		setError(undefined);
		onCancel();
	}, [onCancel]);

	const handleConfirm = useCallback(async () => {
		setError(undefined);

		try {
			const promise = onConfirm();
			if (promise) {
				setLoading(true);
				await promise;
				setSucceeded(true);
				setTimeout(() => setSucceeded(false), 1500);
			}
			onCancel();
		} catch {
			setError('Erreur lors de la confirmation');
		} finally {
			setLoading(false);
		}
	}, [onCancel, onConfirm]);

	const actionsDialog = useMemo(
		(): DialogProps['actionsDialog'] => [
			{
				disabled: isLoading,
				label: cancelLabel,
				onClick: handleCancel,
			},
			{
				loading: isLoading,
				color: 'error',
				persistantErrorMessage: error,
				success: hasSucceeded,
				label: confirmLabel,
				variant: 'contained',
				onClick: handleConfirm,
			},
		],
		[cancelLabel, confirmLabel, error, handleCancel, handleConfirm, hasSucceeded, isLoading],
	);
	return (
		<HarfangConfirmationDialog
			title={title}
			content={message}
			open={open}
			actionsDialog={actionsDialog}
			onClose={onCancel}
		/>
	);
};

export default ConfirmationDialog;
