import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import getConfig from 'next/config';
import { ReactElement, useEffect } from 'react';

type PublicRuntimeConfigType = {
	publicRuntimeConfig: {
		appVersion: string;
		bugsnagApiKey: string;
		bugsnagReleaseStage: string;
		nodeEnv: string;
	};
};

const BugSnagProvider = ({ children }: { children: ReactElement }) => {
	const { publicRuntimeConfig } = getConfig() as PublicRuntimeConfigType;
	const { appVersion, bugsnagApiKey, bugsnagReleaseStage, nodeEnv } = publicRuntimeConfig;

	useEffect(() => {
		if (nodeEnv === 'production' && typeof window !== 'undefined' && bugsnagApiKey && bugsnagReleaseStage) {
			Bugsnag.start({
				apiKey: bugsnagApiKey,
				releaseStage: bugsnagReleaseStage,
				plugins: [new BugsnagPluginReact()],
				appVersion,
			});
		}
	}, [appVersion, bugsnagApiKey, bugsnagReleaseStage, nodeEnv, publicRuntimeConfig]);

	return children;
};

export default BugSnagProvider;
