import { NetworkStatus, useMutation } from '@apollo/client';
import { ConfirmationDialog, DialogProps, Icon, TableColumnType, TableInstance } from '@elipssolution/harfang';
import { mdiAlertCircle, mdiDelete } from '@mdi/js';
import { alpha, Box, Stack, styled, Typography } from '@mui/material';
import numeral from 'numeral';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedNumber } from 'react-intl';

import CheckTable from './CheckTable';
import useSubmittedOrValidatedChecksDataSource from './hooks/useSubmittedOrValidatedChecksDataSource';
import { RemoveCheckType, REMOVE_CHECK, ValidateCheckType, VALIDATE_CHECK } from '../../api/check';
import useSubmittedOrValidatedActionColumn from '../../hooks/useSubmittedOrValidatedActionColumn';
import { CheckType } from '../../types/check';

const ErrorCell = styled('div')(({ theme: { palette, shape, spacing } }) => ({
	flex: 1,
	height: 30,

	backgroundColor: alpha(palette.error.main, 0.2),
	color: palette.error.main,
	border: `1px solid ${palette.error.main}`,
	borderRadius: shape.borderRadius * 2,

	display: 'flex',
	alignItems: 'center',
	gap: spacing(1),

	paddingLeft: spacing(0.5),
}));

const columns: TableColumnType<CheckType>[] = [
	{
		field: 'documentDate',
		flexGrow: 0,
		key: 'documentDate',
		render: ({ documentDate }) => documentDate.toLocaleDateString('fr-FR'),
		sortable: true,
		title: 'Date',
		width: 100,
	},
	{
		key: 'bank',
		render: ({ bank }) =>
			bank?.name ?? (
				<ErrorCell>
					<Icon path={mdiAlertCircle} />
					<Typography variant="caption">Banque manquante</Typography>
				</ErrorCell>
			),
		title: 'Banque',
		width: 125,
	},
	{
		field: 'documentNumber',
		flexGrow: 0,
		key: 'documentNumber',
		sortable: true,
		title: 'Numéro',
		width: 120,
	},
	{
		key: 'masterAccount',
		render: ({ account: { code, masterAccount } }) => {
			const { code: masterAccountCode } = masterAccount ?? {};

			return masterAccountCode ?? code;
		},
		title: 'Compte général',
		width: 150,
	},
	{
		key: 'account',
		render: ({ account: { code, masterAccount } }) => (masterAccount ? code : ''),
		title: 'Code auxiliaire',
		width: 150,
	},
	{
		field: 'name',
		key: 'name',
		sortable: true,
		title: 'Libellé',
		width: 200,
	},
	{
		align: 'right',
		field: 'amount',
		key: 'amount',
		render: ({ amount }) => (
			<Stack paddingRight={1}>
				<FormattedNumber value={numeral(amount).value() ?? 0} style="currency" currency="EUR" />
			</Stack>
		),
		sortable: true,
		title: 'Montant',
		width: 100,
	},
];

type SubmittedOrValidatedCheckTableProps = {
	onCheckSelection: (check: CheckType) => void;
	onCheckValidation: () => void;
	tableInstance: TableInstance;
};

const SubmittedOrValidatedCheckTable = ({
	onCheckSelection,
	onCheckValidation,
	tableInstance,
}: SubmittedOrValidatedCheckTableProps) => {
	const [checkToRemoveId, setCheckToRemoveId] = useState<CheckType['id']>();

	const handleRemoveConfirmationDialogClose = () => setCheckToRemoveId(undefined);

	const [validateCheck] = useMutation<ValidateCheckType>(VALIDATE_CHECK, {
		onCompleted: () => setTimeout(onCheckValidation, 1500),
	});

	const [removeCheck, { data: removeCheckData, loading: isCheckRemovalLoading, reset }] = useMutation<RemoveCheckType>(
		REMOVE_CHECK,
		{
			onCompleted: () => {
				setTimeout(() => {
					handleRemoveConfirmationDialogClose();

					reset();

					tableInstance.reload();
				}, 1500);
			},
		},
	);

	const handleRemove = useCallback(
		() =>
			removeCheck({
				variables: {
					id: checkToRemoveId,
				},
			}),
		[removeCheck, checkToRemoveId],
	);

	const handleValidate = useCallback(
		(id: CheckType['id']) =>
			validateCheck({
				variables: {
					id,
				},
			}),
		[validateCheck],
	);

	const actionColumn = useSubmittedOrValidatedActionColumn({
		onRemove: (id: CheckType['id']) => setCheckToRemoveId(id),
		onValidate: handleValidate,
	});

	const tableColumns = useMemo(() => [...columns, actionColumn], [actionColumn]);

	const {
		dataSource: submittedOrValidatedChecksDataSource,
		hasItems: hasSubmittedOrValidatedChecks,
		networkStatus,
	} = useSubmittedOrValidatedChecksDataSource();

	const removeConfirmationDialogActions = useMemo(
		() =>
			[
				{
					label: 'Annuler',
					loading: isCheckRemovalLoading,
					onClick: handleRemoveConfirmationDialogClose,
				},
				{
					color: 'error',
					label: 'Supprimer',
					loading: isCheckRemovalLoading,
					onClick: handleRemove,
					startIcon: <Icon path={mdiDelete} />,
					success: !!removeCheckData,
					variant: 'contained',
				},
			] as DialogProps['actionsDialog'],
		[handleRemove, removeCheckData, isCheckRemovalLoading],
	);

	// Reload the table if the query has been refetched
	useEffect(() => {
		networkStatus === NetworkStatus.refetch && tableInstance.reload();
	}, [tableInstance, networkStatus]);

	return hasSubmittedOrValidatedChecks ? (
		<>
			<Box flex={1} paddingBottom={2}>
				<CheckTable
					columns={tableColumns}
					dataSource={submittedOrValidatedChecksDataSource}
					onCheckSelection={onCheckSelection}
					tableInstance={tableInstance}
					title="Chèques à valider"
				/>
			</Box>

			<ConfirmationDialog
				actionsDialog={removeConfirmationDialogActions}
				content="Les informations renseignées seront perdues."
				open={!!checkToRemoveId}
				onClose={handleRemoveConfirmationDialogClose}
				title="Êtes-vous sûr de vouloir supprimer cette pièce ?"
			/>
		</>
	) : null;
};

export default SubmittedOrValidatedCheckTable;
